import { Component, OnInit, ViewChild } from '@angular/core';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
defineLocale('es', esLocale); 
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.scss']
})
export class VisitorsComponent implements OnInit {

	locale = window.navigator.language;
	public visitants = [];
    public countries = [];
    public bedrooms = [];
    public services = [];
    public totalServices = 0;
    public totalGuides = 0;
    public totalConsignation = 0;
    public customer_id = "";
	public reservation = {id:0, responsible:'', document_type:'', document_number:'', admission_date:'', departure_date:'', total_value:0, confirmed: 0, payment:0, link_payment:0, observations: '', update_invoice:0};
  	public token = "";
    facturation_form: FormGroup;
    public url;
    public approvePolitics = 0;
    public sendForm = false;
    bloquear: boolean = false;
    public archivos = "";

    @ViewChild('modal') modal;
    modalRef: BsModalRef;
        config = {
        animated: true
    };

  	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                private localeService: BsLocaleService,
                private router: Router,
                private sanitizer: DomSanitizer,
                private modalService: BsModalService,
                fb: FormBuilder,
                public route: ActivatedRoute) 
  	{
  		this.localeService.use(this.locale);
  		this.route.params.subscribe(params => {
            this.token = params['token'];
        });
        this.facturation_form = fb.group({
            'responsible': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'address': ['', Validators.required],
            'phone': ['', Validators.required],
            'document_archive': [''],
            'document_number': ['',  Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(20)])],
            'document_type': ['', Validators.required]
        });
  	}

  	ngOnInit() {
  		this.getReservation(this.token);
        this.getCountries();
       
  	}

  	showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.hide()},500);
    }

    /**
     * Add or less rows of visitants 0:less row, 1:Add row
     */
    changeVisitants(type)
  	{	
  		if(type == 1)
  		{
  			var data = {id:0, name:'', document:'', birthdate:'', origin:'', nationality:'', selectedNationality:null};
  			this.visitants.push(data);
  		} 
  		else 
  		{
  			this.visitants.pop();
  		}
  	}

  	/**
     * Delete rows of visitants 
     * i: position on visitants array
     * id: visitant id, if id > 0 delete on database
     */
  	deleteVisitant(i, id)
  	{	
        if(id > 0)
        {	
        	let url = 'reservation-visitor?id='+id;
            this.service.queryPostRegular(url, new FormData).subscribe(
                response=>
                {      
                    let result = response.json(); 
                    if(!result.success)
                    {
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                    }
                 },
                err => 
                {	
                	this.hideLoader();
	            	let message = 'Parece que tenemos un error, intenta mas tarde.'
	                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );        
        }
  		this.visitants.splice(i, 1);
  	}

  	/**
     * Validate token to show reservation 
     */
  	getReservation(token)
  	{
  		this.showLoader();
  		let url = 'reservations/token/'+token;
  		this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.success){
	                let reservation = result.reservation;
                    this.url = this.getIframe(reservation.id);
	                this.reservation['id'] = reservation.id;
                    this.customer_id = reservation.customer_id;
	                this.reservation['responsible'] = reservation.responsible;
	                this.reservation['observations'] = reservation.observations;
	                this.reservation['document_type'] = reservation.document_type;
	                this.reservation['document_number'] = reservation.document_number;
	                this.reservation['admission_date'] = reservation.admission_date;
	                this.reservation['departure_date'] = reservation.departure_date;
	                this.reservation['confirmed'] = reservation.confirmed;
	                this.reservation['payment'] = reservation.payment;
                    this.reservation['link_payment'] = reservation.link_payment;
                    this.reservation['update_invoice'] = reservation.update_invoice;
	                this.totalServices = result.totalServices;
                    this.totalGuides = result.totalGuides;
                    this.totalConsignation = result.totalConsignation;

	                let visitors = result.visitors;
	                if(visitors.length > 0)
	                {    
	                    for (let i = 0; i < visitors.length; i++)
	                    {    
	                        let id = visitors[i].id;
	                        let name = visitors[i].name;
	                        let document = visitors[i].document;
	                        let birthdate = visitors[i].birthdate;
	                        let origin = visitors[i].origin;
	                        let nationality = visitors[i].nationality;
	                        let selectedNationality;
	                        if(origin == 'nacional'){
	                            selectedNationality = visitors[i].nationality;
	                        }
	                        else{
	                            selectedNationality = {id: visitors[i].country, name: visitors[i].nationality};
	                        }

	                        let data = {id: id, name:name, document:document, birthdate:birthdate, origin:origin, nationality:nationality, selectedNationality: selectedNationality};
	                        this.visitants.push(data);
	                    }
	                }
                    else
                    {
                        for (var i = 0; i < 3; i++) {
                            var data = {id:0, name:'', document:'', birthdate:'', origin:'', nationality:'', selectedNationality:null};
                            this.visitants.push(data);
                        }
                    }

                    let customer = result.customer;
                    if(null != reservation.customer_id){
                        this.facturation_form.controls['responsible'].setValue(customer.responsible);
                        this.facturation_form.controls['email'].setValue(customer.email);
                        this.facturation_form.controls['address'].setValue(customer.address);
                        this.facturation_form.controls['phone'].setValue(customer.phone);
                        this.facturation_form.controls['document_type'].setValue(customer.document_type);
                        this.facturation_form.controls['document_number'].setValue(customer.document_number);
                    }

                    this.services = result.services;
                    this.bedrooms = result.bedrooms;
                } 
                else 
                {
                	this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                	this.router.navigate(['/']);
                }
                this.hideLoader();

            },
            err => 
            {	
            	this.hideLoader();
            	let message = 'Parece que tenemos un error, intenta mas tarde.'
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                //console.log(err);
            }
        );
  	}


    getIframe(reservationId)
    {    
        let url = 'https://portal.aguasdemanizales.com.co/Portal/Boton.aspx?tp=btn&t=5&c=' + reservationId;
        let iframe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return iframe;
    }

    /*
    * Get countries list
    */  
    getCountries()
    {
        let url = 'countries';
          this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.countries = result;
            },
            err => 
            {    
                //console.log(err);
            }
        );    
    }


    onItemSelect(item:any, i)
    {    
        let country = item;
        if(null != country)
        {
            this.visitants[i]['nationality'] = country.name;
        }
        else
        {
            this.visitants[i]['nationality'] = '';
        }    
    }

  	/**
     * i: Position in the visitor matrix.
	 * if the origin is national, the nationality will be well blocked, otherwise it is an open field
     */
  	changeOrigin(i)
    {
        var origin = this.visitants[i]['origin'];
        if(origin == 'nacional')
        {
            this.visitants[i]['nationality'] = 'Colombia';
        }
        else
        {
            this.visitants[i]['nationality'] = '';
        }
    }

    /**
     * Save visitants of reservation
     */
  	save()
  	{    
        if(false == this.sendForm){
            this.showLoader();         
            let id = this.reservation['id'];
            let aux_visitors = JSON.stringify(this.visitants);
            
            let body = new FormData();
            body.append('visitors', aux_visitors);

            let invoice = 0;
            if(1 == this.reservation['update_invoice']){
                for (let v in this.facturation_form.controls) {
                    this.facturation_form.controls[v].markAsTouched();
                }

                if (this.facturation_form.invalid) {
                    this.hideLoader();
                    return;
                }

                body.append('customer_id', this.customer_id);
                body.append('responsible', this.facturation_form.controls['responsible'].value);
                body.append('email', this.facturation_form.controls['email'].value);
                body.append('address', this.facturation_form.controls['address'].value);
                body.append('phone', this.facturation_form.controls['phone'].value);
                body.append('document_type', this.facturation_form.controls['document_type'].value);
                body.append('document_number', this.facturation_form.controls['document_number'].value);
                body.append('document_archive', this.archivos);
                invoice = 1;
            }

            body.append('invoice', invoice+'');
            //this.sendForm = true;
            this.service.queryPostRegular('reservations/visitants/'+id, body).subscribe(
                response=>
                {    
                    this.hideLoader();  
                    let result = response.json();

                    if (result.message=='Debe adjuntar el documento') {
                        console.log(result.message);
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    } 

                    if(result.message === 'ya registrado'){
                        let message = 'El documento ya esta registrado';
                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                    
                    if(result.success){
                        //this.toastr.success(result.message);
                        let visitors = result.visitors;
                        if(visitors.length > 0)
                        {    
                            this.visitants = [];
                            for (let i = 0; i < visitors.length; i++)
                            {    
                                let id = visitors[i].id;
                                let name = visitors[i].name;
                                let document = visitors[i].document;
                                let birthdate = visitors[i].birthdate;
                                let origin = visitors[i].origin;
                                let nationality = visitors[i].nationality;
                                let selectedNationality;
                                if(origin == 'nacional'){
                                    selectedNationality = visitors[i].nationality;
                                }
                                else{
                                    selectedNationality = {id: visitors[i].country, name: visitors[i].nationality};
                                }

                                let data = {id: id, name:name, document:document, birthdate:birthdate, origin:origin, nationality:nationality, selectedNationality: selectedNationality};
                                this.visitants.push(data);
                            }
                        }
                        //this.toastr.success(result.message);
                        this.approvePolitics = 0;

                        this.sendForm = true;
                        this.openModal();
                        this.bloquear = true;
                    }
                    else{
                        let message = '';
                        if(result.message.length > 0)
                        {
                            result.message.forEach(function(element) {
                                message += element+'<br>';
                            });
                        }
                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                    }                    
                },
                err => 
                {    
                    this.hideLoader();
                    let message = 'Parece que tenemos un error, intenta mas tarde.'
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                    //console.log(err);
                }
            ); 
        }     
  	}

    /**
    * Function to validate numbers
    *
    * @param e EventHtml
    */
    public validateNumber(e)
    {
        if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight"
         && e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
            var key = window.event ? e.which : e.keyCode;
            if (key < 48 || key > 57) {
                e.preventDefault();
            }
        }
    }


    politics()
    {
        console.log(this.approvePolitics);
    }

    captureFile(event): any {
        const archiveCapture = event.target.files[0];
        this.archivos = archiveCapture;
    }

    openModal() {
        this.modalRef = this.modalService.show(this.modal, {class: 'modal-md modal-dialog-centered', ignoreBackdropClick: true,});
        setTimeout(this.modalRef.hide, 4000);
    }
}
