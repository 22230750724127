import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { UsersComponent } from './users/users.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { ProfileComponent } from './profile/profile.component';
import { ServicesComponent } from './services/services.component';
import { HomeComponent } from './home/home.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ReportsComponent } from './reports/reports.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { CustomersComponent } from './customers/customers.component';
import { LandingComponent } from './landing/landing.component';
import { ServiceComponent } from './service/service.component';
import { GuidesComponent } from './guides/guides.component';
import { VisitorsComponent } from './visitors/visitors.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MessagesComponent } from './messages/messages.component';

const routes: Routes = [
  	{ path: 'login', component: LoginComponent },
  	{ path: '', component: LandingComponent },
  	{ path: 'service/:serviceId', component: ServiceComponent },
  	{ path: 'reservation/:token', component: VisitorsComponent },
  	{ path: '',
	    component: LayoutComponent,
	    children: 
	    [
	      	{ path: 'users/:view', component: UsersComponent },
	      	{ path: 'permissions', component: PermissionsComponent },
	      	{ path: 'profile', component: ProfileComponent },
	      	{ path: 'services/:view', component: ServicesComponent },
	      	{ path: 'home', component: HomeComponent },
	      	{ path: 'reservations/:view', component: ReservationsComponent },
	      	{ path: 'reports', component: ReportsComponent },
	      	{ path: 'accommodation/:view', component: AccommodationComponent },
	      	{ path: 'customers/:view', component: CustomersComponent },
	      	{ path: 'guides/:view', component: GuidesComponent },
	      	{ path: 'messages', component: MessagesComponent },
	    ] 
	},
  	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  	imports: [ RouterModule.forRoot(routes) ],
  	exports: [ RouterModule ]
})
export class AppRoutingModule {}