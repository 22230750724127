import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { SessionService } from '../session.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { TranslateService } from '@ngx-translate/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as moment from 'moment';
defineLocale('es', esLocale); 


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

	locale = 'es';
	customer_form: FormGroup;
  	checkboxValue: any;

  	public view = 'index';
  	public customers = [];
  	public pages = [];
    public currentPage = 0;
    public search = '';
    public rows = 10;

    public customer_id = 0;
    private user = {name:'', url_image:''};
    public permissions = [];

  	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                public session: SessionService,
                fb: FormBuilder,
                public route: ActivatedRoute,
                private router: Router,
  				private localeService: BsLocaleService)
  	{	
  		this.localeService.use(this.locale);
           this.route.params.subscribe(params => {
            this.view = params['view'];
        }); 
  		this.customer_form = fb.group({
	    	'responsible': ['', Validators.required],
	    	'document_number': ['', Validators.required],
	    	'document_type': ['', Validators.required],
	    	'status': [true, Validators.required],
            'phone': ['', Validators.compose([Validators.minLength(7)])],
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'address': ['', Validators.required]
	    });	
   	}

  	ngOnInit() {
        this.getUser();  
  		this.getCustomers('');
  	}

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });
    }

  	getCustomers(page){
        console.log("METODO EN CUSTOMERS"+page);
  		let url = 'customers?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.customers = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                //console.log(err);
            }
        );
  	}

    getPage(page)
    {    
        if(page != this.currentPage)
        {
            this.getCustomers(page);
        }    
    }

  	changeView(view){
  		this.view = view;
  		this.customer_form.reset();
        this.router.navigate(['/customers/'+view]);  
  	}
	

  	submitForm() {
	    for (let v in this.customer_form.controls) {
	      this.customer_form.controls[v].markAsTouched();
	    }

	    if (this.customer_form.invalid) {
	    	return;
	    	// You will get form value if your form is valid
	        //var formValues = this.customer_form.getRawValue;
	    }
	    let status = '0';
	    if(this.customer_form.controls['status'].value){
	    	status = '1';
	    }

	    let body = new FormData();
        body.append('responsible', this.customer_form.controls['responsible'].value);
        body.append('document_number', this.customer_form.controls['document_number'].value);
        body.append('document_type', this.customer_form.controls['document_type'].value);
        body.append('phone', this.customer_form.controls['phone'].value);
        body.append('address', this.customer_form.controls['address'].value);
        body.append('email', this.customer_form.controls['email'].value);
        body.append('status', status);

        this.service.queryPost('customers', body).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.message === 'ya registrado'){
                    let message = 'El documento ya esta registrado';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
                if(result.success){
                    this.router.navigate(['/customers/index']);  
                    this.view = 'index';
                    this.toastr.success(result.message);
                    this.customer_form.reset();
                    this.getCustomers(this.currentPage);
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        ); 
	};

	edit(customerId)
	{
		this.service.queryGet('customers/'+customerId).subscribe(
            response=>
            {      
                let result = response.json(); 
                let customer = result;
                this.customer_id = customer.id;
                this.customer_form.controls['responsible'].setValue(customer.responsible);
                this.customer_form.controls['document_number'].setValue(customer.document_number);
                this.customer_form.controls['document_type'].setValue(customer.document_type);
                this.customer_form.controls['phone'].setValue(customer.phone);
                this.customer_form.controls['address'].setValue(customer.address);
                this.customer_form.controls['email'].setValue(customer.email);

                if(customer.status == 1){
                	this.customer_form.controls['status'].setValue(true);
                }
                else{
                	this.customer_form.controls['status'].setValue(false);	
                }
                this.router.navigate(['/customers/form']);  
                this.view = 'form';

            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );
	}

	update() {
	    for (let v in this.customer_form.controls) {
	      this.customer_form.controls[v].markAsTouched();
	    }

	    if (this.customer_form.invalid) {
	    	return;
	    	// You will get form value if your form is valid
	        //var formValues = this.customer_form.getRawValue;
	    }
	    let status = '0';
	    if(this.customer_form.controls['status'].value){
	    	status = '1';
	    }

	    let id = this.customer_id;
	    let body = new FormData();
	    body.append('_method', 'PUT');
        body.append('responsible', this.customer_form.controls['responsible'].value);
        body.append('document_number', this.customer_form.controls['document_number'].value);
        body.append('document_type', this.customer_form.controls['document_type'].value);
        body.append('phone', this.customer_form.controls['phone'].value);
        body.append('address', this.customer_form.controls['address'].value);
        body.append('email', this.customer_form.controls['email'].value);
        body.append('status', status);

        this.service.queryPost('customers/'+id, body).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.success){
                    this.router.navigate(['/customers/index']);  
                    this.view = 'index';
                    this.toastr.success(result.message);
                    this.customer_form.reset();
                    this.customer_id = 0;
                    this.getCustomers(this.currentPage);
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        ); 
	};

	deleteCustomer(customerId)
    {
        
        this.service.queryDelete('customers/'+customerId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list accommodations
                    this.getCustomers(this.currentPage);
                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                }
                else{
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
                }
            },
            err => 
            {
                //console.log(err);
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );
    }

    confirmDelete(customerId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteCustomer(customerId);
            }
        });
    }

    
}
