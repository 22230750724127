import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { UsersComponent } from './users/users.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { PermissionsComponent } from './permissions/permissions.component';
import { ProfileComponent } from './profile/profile.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ToastrModule } from 'ngx-toastr';
import { ImageCropperModule } from 'ngx-image-cropper';

import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

import { AgmCoreModule } from '@agm/core';
import { FormsComponent } from './forms/forms.component';
import { ServicesComponent } from './services/services.component';

import { CustomFormsModule } from 'ng2-validation';
import { HomeComponent } from './home/home.component';
import { ReservationsComponent } from './reservations/reservations.component';
import { ReportsComponent } from './reports/reports.component';
import { AccommodationComponent } from './accommodation/accommodation.component'

import { ChartModule } from 'angular2-chartjs';
import { CustomersComponent } from './customers/customers.component';

import es from '@angular/common/locales/es';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { NgSelectModule } from '@ng-select/ng-select';
import { LandingComponent } from './landing/landing.component';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { CollapseModule } from 'ngx-bootstrap';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ServiceComponent } from './service/service.component';
import { TranslateComponent } from './translate/translate.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { GuidesComponent } from './guides/guides.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CKEditorModule } from 'ngx-ckeditor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { VisitorsComponent } from './visitors/visitors.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { InformationComponent } from './information/information.component';
import { MessagesComponent } from './messages/messages.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: null,
  maxFilesize: 50,
  acceptedFiles: 'image/*'
};

@NgModule({
  	declarations: 
  	[
    	AppComponent,
    	LoginComponent,
    	LayoutComponent,
    	UsersComponent,
    	PermissionsComponent,
    	ProfileComponent,
    	FormsComponent,
    	ServicesComponent,
    	HomeComponent,
    	ReservationsComponent,
    	ReportsComponent,
    	AccommodationComponent,
    	CustomersComponent,
    	LandingComponent,
    	ServiceComponent,
    	TranslateComponent,
    	GuidesComponent,
    	VisitorsComponent,
    	PageNotFoundComponent,
    	InformationComponent,
    	MessagesComponent
  	],
  	imports: 
  	[
    	BrowserModule,
      BrowserAnimationsModule,
      HttpModule,
    	AppRoutingModule,
      HttpClientModule,
      FormsModule,
      NgSelectModule,
      AngularFontAwesomeModule,
      UiSwitchModule,
      CustomFormsModule,
      ReactiveFormsModule,
      ChartModule,
      NgxPageScrollCoreModule,
      NgxUploaderModule,
      NgxSpinnerModule,
      CKEditorModule,
      NgSelectModule,
      CarouselModule.forRoot(),
      CollapseModule.forRoot(),
      BsDatepickerModule.forRoot(),
      AccordionModule.forRoot(),
      ModalModule.forRoot(),
      ToastrModule.forRoot(),
      ImageCropperModule,
      DropzoneModule,
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
          }
      }),
      CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory
      }),
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyDaNU3U4WCLVcT-Z_g--085HQwISFrZbos'
      })
  	],
  	providers: [{
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }],
  	bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

