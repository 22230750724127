import { Injectable } from '@angular/core';
import { ApirestService } from './apirest.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

	public token = new BehaviorSubject('');
	public userSession = new BehaviorSubject('');
	constructor(public service: ApirestService, private router: Router) { }


	setToken(token: string)
	{
		this.token.next(token);
	}

	getToken(): Observable<any> 
	{
		return this.token.asObservable();
	}

	setUserSession(user: string)
	{
	  this.userSession.next(user);		
	}

 	getUserSession(): Observable<any> 
 	{
	  return this.userSession.asObservable();
	}

	validUserSession()
	{
    let res;
    this.service.queryPost('me', FormData).subscribe(
        response=>
        {      
            let result = response.json();
            if(null != result.user)
            {
            	this.setUserSession(JSON.stringify(result.user));
            }
            else
            {
              sessionStorage.setItem('sid', null);
            }
        },
        err => 
        {
          sessionStorage.setItem('sid', null);
        }
    );
	}

}
