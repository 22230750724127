import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { SessionService } from '../session.service';
import { SingletonService } from '../singleton.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

	public roles = [];
    private roleSelected = 0;
    private oneAtTime = true;
    public showInputRole = false;
    public roleEdit = false;
    public idRole = 0;
    public showRoles = false;
    private roleName = "";
    public sections = [];
    //public configuration= {code:"", date:"", edition:"", approve: Date};
    configuration_form: FormGroup;
    public configurations='';
    public view = 'index';
    public code='';
    public date='';
    public edition='';
    public approve=Date;

  	constructor(public service: ApirestService,
                private singleton: SingletonService,
                private session: SessionService,
  				private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService,
                public route: ActivatedRoute) 
  	{ 
        this.route.params.subscribe(params => {
            this.view = params['view'];
        });
  	}

  	ngOnInit() 
  	{
        //se valida la session
        this.singleton.getUserSession();
  		this.getRoles();
        this.configuration_form = new FormGroup({
            code: new FormControl(''),
            date: new FormControl(''),
            edition: new FormControl(''),
            approve: new FormControl('')
        });
        this.viewConfigurations()
  	}

  	getRoles()
  	{
  		this.service.queryGet('roles').subscribe(
            response=>
            {      
                this.roles = response.json(); 

                if(this.roles.length > 0)
                {
                    this.roleSelected = this.roles[0]['id'];

                    this.getPermissions();
                }
            },
            err => 
            {
            	//console.log(err);
            }
        );
  	}

    selectRole(role_id)
    {
        this.roleSelected = role_id;
        this.getPermissions();
    }

    getPermissions()
    {
        this.service.queryGet('permissions/'+this.roleSelected).subscribe(
            response=>
            {      
                let permissions = response.json(); 

                let sections = [];

                if(permissions.length > 0)
                {
                    this.sections = [];
                    let index = -1;
                    let section = {};

                    for (var i = 0; i < permissions.length; ++i) 
                    {
                        index = sections.indexOf(permissions[i]['section']);
                        if(index == -1)
                        {
                            section = {name: permissions[i]['section_name'], 
                                        permissions: [{id: permissions[i]['id'], 
                                                        name: permissions[i]['permission_name'],
                                                        allowed: permissions[i]['allowed'],
                                                        real_name: permissions[i]['name']}]};
                            sections.push(permissions[i]['section']);

                            this.sections.push(section);
                        }
                        else
                        {
                            section = this.sections[index];
                            section['permissions'].push({id: permissions[i]['id'], 
                                                        name: permissions[i]['permission_name'],
                                                        allowed: permissions[i]['allowed'],
                                                        real_name: permissions[i]['name']});
                            this.sections[index] = section;
                        }
                    }
                }

            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    cancelRole()
    {
        this.showInputRole = false;
        this.roleName = '';
    }

    newRole()
    {
        if(!this.showInputRole)
        {
            this.showInputRole = true;
        }
        else if(this.roleName != '')
        {
            let body = new FormData();
            if(this.roleEdit)
            {
                body.append('id', this.idRole+'');
            }
            else
            {
                body.append('id', '0');
            }
            body.append('role', this.roleName);

            this.service.queryPost('roles', body).subscribe(
                response=>
                {      
                    this.getRoles();
                    this.showInputRole = false;
                    this.roleEdit = false;
                    this.roleName = '';
                    let result = response.json();

                    this.toastr.success(result.message);
                },
                err => 
                {
                    //console.log(err);
                    let message = 'Parece que tenemos un error, intentalo mas tarde.';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );
        }
    }

    editRole(id)
    {
        let body = new FormData();
        this.service.queryPost('roles/'+id, body).subscribe(
            response=>
            {      
                let result = response.json();
                this.showInputRole = true;
                this.roleEdit = true;
                this.idRole = result.id
                if('' != result.name)
                {
                    let name = result.name;
                    this.roleName = name;
                }
            },
            err => 
            {
                //console.log(err);
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    changePermissions(id)
    {
        let sectionPermissions = [];
        let permissions = [];

        if(this.sections.length > 0)
        {
            for (var i = 0; i < this.sections.length; ++i) 
            {
                sectionPermissions = this.sections[i]['permissions'];

                for (var j = 0; j < sectionPermissions.length; ++j) 
                {
                    if(sectionPermissions[j]['allowed'] || sectionPermissions[j]['id'] == id)
                    {
                        permissions.push(sectionPermissions[j]['real_name']);
                    }
                }
            }
        }

        let permissionsJoin = permissions.join();
        let body = new FormData();
        body.append('role_id', this.roleSelected+'');
        body.append('permissions', permissionsJoin);

        this.service.queryPost('sync-permissions', body).subscribe(
            response=>
            {      
                //this.getRoles();
                //this.showInputRole = false;
                let result = response.json();

                this.toastr.success(result.message);
            },
            err => 
            {
                //console.log(err);
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /**
     * Deletes the user
     * @param string userId id user to delete
     */
    deleteRol(RolId)
    {
        this.service.queryDelete('roles/'+RolId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the roles list 
                    this.getRoles();

                    this.toastr.success(result.message);
                }else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                //console.log(err);
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

     /**
     * Ask for confirmation before to delete the user
     * @param string userId id user to delete
     */
    confirmDelete(RolId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteRol(RolId);
            }
        });
    }

    editConfiguration()
    {   
        this.service.queryPost('reservations-updateHeadExcel', this.configuration_form.value).subscribe(
            response=>
            {   
                if(response){
                    this.viewConfigurations();
                    this.router.navigate(['permissions']); 
                    let message = 'Se actualizó con exito';
                    this.toastr.success(message, 'Actualizado', {enableHtml: true, positionClass: 'toast-top-center'});
                }   
            },
            err => 
            {
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    viewConfigurations()
    {
        this.service.queryGet('reservations-showHeadExcel').subscribe(
            response=>
            {  
                let configuration = response.json();
                let data= configuration.HeadExcel;
                if (configuration!= null){
                    this.code= data.code;
                    this.date= data.date;
                    this.edition= data.edition;
                    this.approve= data.approve;
                }
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

}
