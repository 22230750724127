import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	private sessionUser = {};
    private userSession = {name:'', url_image:''};
    public permissions = [];
    //Form variables
    public user_form: FormGroup;
    private user = {id: 0, name: '', email: '', password: '', status: true, role_id: ''};
    private roles = [];

    //Variables for users list with (pagination, select rows, search)
    private users = [];
    private rows = 10; //Default value
    private search = '';
    private pages = [];
    private currentPage = 1;
    public view = 'index';

    //Canvas variables
    private canvasUser = false;
    private showOverlay = false;

  	constructor(public service: ApirestService,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService,
                public session: SessionService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                public route: ActivatedRoute,
                fb: FormBuilder) 
  	{   
        this.route.params.subscribe(params => {
            this.view = params['view'];
        });

        this.user_form = fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'password': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            'status': [true],
            'role_id': ['', Validators.required]
        });
  	}

  	ngOnInit() 
  	{
        //Validate session user
        this.singleton.getUserSession();
        //Function to get the source data
        this.getUser();
        this.getUsers('');
        this.getRoles();
  	}

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.userSession = JSON.parse(user);
                this.translate.use(this.userSession['language']);
                this.permissions = this.userSession['permissions'];
            }
        });
    }

    /**
     * Obtains the list of data with the paging information and search results
     */
    getUsers(page)
    {
        let url = 'users?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                this.users = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                
            }
        );
    }

    /**
     * Calls the elements of the given page
     * @param int page Next page to paginate
     */
    getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getUsers(page);
        }
    }

    /**
     * Gets the roles to show on create form
     */
    getRoles()
    {
        this.service.queryGet('roles').subscribe(
            response=>
            {      
                let result = response.json(); 
                this.roles = result;
            },
            err => 
            {
                
            }
        );
    }

    showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.hide()},500);
    }

    /**
     * This function opens the create form
     */
    changeView(view){
        this.view = view;
        this.router.navigate(['/users/'+view]); 
    }

    /**
     * Function that saves or update an user
     */
    saveUser()
    {    
        for (let v in this.user_form.controls) {
          this.user_form.controls[v].markAsTouched();
        }

        if (this.user_form.invalid) {
            return;
        }
        
        let status = '0';
        if(this.user_form.controls['status'].value){
            status = '1';
        }

        this.showLoader();

        let body = new FormData();
        body.append('id', this.user['id']+'');
        body.append('name', this.user_form.controls['name'].value);
        body.append('email', this.user_form.controls['email'].value);
        body.append('password', this.user_form.controls['password'].value);
        body.append('role_id', this.user_form.controls['role_id'].value);
        body.append('status', status);

        this.service.queryPost('users', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reinit the global user variable
                    this.user = {id: 0, name: '', email: '', password: '', status: true, role_id: ''};

                    //Show success message
                    this.toastr.success(result.message);

                    //Reload the list users
                    this.getUsers('');

                    //Hide the ngx-spinner
                    this.hideLoader();
                    this.user_form.reset();
                    this.view = 'index';
                    this.router.navigate(['/users/index']); 
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.hideLoader();
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /**
     * It searches the user and set to global user variable to edit
     * @param string userId Field to search the user for id
     */
    editUser(userId)
    {    
        this.showLoader();
        this.service.queryGet('users/'+userId).subscribe(
            response=>
            {      
                let user = response.json(); 
                this.user['id'] = user.id;
                this.user_form.controls['name'].setValue(user.name);
                this.user_form.controls['email'].setValue(user.email);

                if(user.role != undefined)
                {    
                    this.user_form.controls['role_id'].setValue(user.role.id);
                }

                if(user.status == 1)
                {
                    this.user_form.controls['status'].setValue(true);
                }
                else
                {
                    this.user_form.controls['status'].setValue(false);
                }

                this.view = 'form';
                this.router.navigate(['/users/form']); 
                this.hideLoader();
            },
            err => 
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /**
     * Deletes the user
     * @param string userId id user to delete
     */
    deleteUser(userId)
    {
        this.service.queryDelete('users/'+userId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list users
                    this.getUsers('');

                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /**
     * Ask for confirmation before to delete the user
     * @param string userId id user to delete
     */
    confirmDelete(userId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteUser(userId);
            }
        });
    }

    /**
     * Call the function to export, you must send the same search parameters
     * @param {string} type (pdf or excel)
     */
    exportData(type)
    {
        let url = 'export-users?type=' + type;
        url += '&search=' + this.search;

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json(); 
                window.open(result,'_blank');
            },
            err => 
            {
                
            }
        );
    }
}
