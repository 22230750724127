import { Component, OnInit } from '@angular/core';
import { ApirestService } from '../apirest.service';
import { Router } from '@angular/router';
import { SessionService } from '../session.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	  private user = {email: '', password: ''};
    public login_form = true;
    public remember_form = false;
    public loader = false;

    user_form: FormGroup;
    user_form_reset: FormGroup;
    checkboxValue: any;

  	constructor(public service: ApirestService,
                private session: SessionService,
                private toastr: ToastrService,
                fb: FormBuilder,
  			    private router: Router) 
  	{ 
        this.user_form = fb.group({
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'password': ['', Validators.required]
        });

        this.user_form_reset = fb.group({
            'email': ['', Validators.compose([Validators.required, Validators.email])]
        });
  	}

  	ngOnInit() 
  	{
  		if('null' != sessionStorage.getItem('sid') && null != sessionStorage.getItem('sid') && undefined != sessionStorage.getItem('sid'))
  		{
  		  	this.router.navigate(['/home']);
  		}
  	}

    /**
     * Verify email and password to authenticate users
     */
  	login()
  	{  
        for (let v in this.user_form.controls) {
          this.user_form.controls[v].markAsTouched();
        }

        if (this.user_form.invalid) {
            return; // You will get form value if your form is valid
        }

        this.loader = true;  
  		let body = new FormData;
        body.append('email', this.user_form.controls['email'].value);
  		body.append('password', this.user_form.controls['password'].value);

  		this.service.queryPostRegular('login', body).subscribe(
            response=>
            {      
                this.loader = false; 
                let result = response.json();
                if(result.success) 
                {
                    let token = result.token;
                    let characters = "abcdefghijkmnpqrtuvwxyzABCDEFGHJKMNPQRTUVWXYZ2346789";
                    let text = "";
                    for (let i=0; i<4; i++) text +=characters.charAt(Math.floor(Math.random()*characters.length)); 
                    token = 'ey'+text+token;
                    sessionStorage.setItem('sid', token);
                    this.session.setUserSession(JSON.stringify(result.user));
                    this.router.navigate(['/home']);
                }
                else
                {
                 	//console.log(result.message);
                  this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {    
                this.loader = false; 
            	  //console.log(err);
                let message = 'Parece que tenemos un error, inténtalo de nuevo más tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
  	}

    /**
     * Send mail to recover password
     */  
    sendMail()
    {    
        for (let v in this.user_form_reset.controls) {
          this.user_form_reset.controls[v].markAsTouched();
        }

        if (this.user_form_reset.invalid) {
            return; // You will get form value if your form is valid
        }
          
        this.loader = true;
        let body = new FormData;
        body.append('email', this.user_form_reset.controls['email'].value);

        this.service.queryPost('recover-password', body).subscribe(
            response=>
            {      
                this.loader = false;
                let result = response.json(); 
                if(result.success)
                {
                    //Show success message
                    this.user_form_reset.reset();
                    this.loader = false;
                }
                else
                {    
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {    
                this.loader = false; 
                //console.log(err);
                let message = 'Parece que tenemos un error, inténtalo de nuevo más tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    
    }

    /**
     * Hide login form, show remember form
     */
    forgotPass()
    {
        this.login_form = false;
        this.remember_form = true;
    }

    /**
     * Hide remember form, show login form
     */
    back()
    {
        this.login_form = true;
        this.remember_form = false;
    }
}
