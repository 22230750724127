import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { SessionService } from '../session.service';
import { SingletonService } from '../singleton.service';

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from "date-fns";
import { Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { CalendarEvent, CalendarDateFormatter, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK, CalendarMonthViewDay  } from 'angular-calendar';
import localeEs from '@angular/common/locales/es';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';

registerLocaleData(localeEs);


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    locale = 'es';
	public user = {name:'', url_image:''};
    
    public menuXs = false;
    public showUserMenu = false;
    public mediaQuery = 'lg';
    public permissions = [];

  	constructor(private translate: TranslateService,
                public service: ApirestService,
                public session: SessionService,
                private singleton: SingletonService,
                private router: Router) 
  	{ 

  	}

  	ngOnInit() 
  	{
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });

        this.getMediaQuery();
  	}

    getMediaQuery()
    {
        let width = screen.width;

        if(width <= 575.98)
        {
            this.mediaQuery = 'xs';
            this.menuXs = true;
        }
        else if(width <= 767.98)
        {
            this.mediaQuery = 'sm';
            this.menuXs = true;
        }
        else if(width <= 991.98)
        {
            this.mediaQuery = 'md';
        }
        else if(width <= 1199.98)
        {
            this.mediaQuery = 'lg';
        }
    }

    /**
     * Logout from api and redirect to login
     */
    logout()
    {
        let body = new FormData();

        this.service.queryPost('logout', body).subscribe(
            response=>
            {
                sessionStorage.setItem('sid', null);
                this.router.navigate(['/login']);
            },
            err => 
            {
                /*console.log(err);
                console.log(err['status']);*/
                if(err['status'] == 401)
                {
                    sessionStorage.setItem('sid', null);
                    this.router.navigate(['/login']);
                }
            }
        );
    }
}
