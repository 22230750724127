import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { SessionService } from '../session.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {

	guide_form: FormGroup;
  	checkboxValue: any;

  	public view = 'index';
  	public guides = [];
  	public pages = [];
    public currentPage = 0;
    public search = '';
    public rows = 10;
    public guide_id = 0;

    private user = {name:'', url_image:''};
    public permissions = [];

	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                public session: SessionService,
                public route: ActivatedRoute,
                private router: Router,
                fb: FormBuilder) 
	{     
        this.route.params.subscribe(params => {
            this.view = params['view'];
        }); 

		this.guide_form = fb.group({
	    	'id': [''],
	    	'name': ['', Validators.required],
	    	'document': ['', Validators.required],
	    	'email': ['',  Validators.compose([Validators.required, Validators.email])],
	    	'phone': ['', Validators.required],
	    	'status': [true, Validators.required]
	    });
	}

    ngOnInit() {
        this.getUser();
    	this.getGuides('');
    }

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });
    } 

    getGuides(page)
    {
    	let url = 'guides?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.guides = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                
            }
        );
    }

    getPage(page)
    {    
        if(page != this.currentPage)
        {
            this.getGuides(page);
        }    
    }

    changeView(view){
  		this.view = view;
        this.router.navigate(['/guides/'+view]);  
  	}

    showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
       setTimeout(()=>{s.spinner.hide()},500);
    }  

  	submitForm() {
	    for (let v in this.guide_form.controls) {
	      this.guide_form.controls[v].markAsTouched();
	    }

	    if (this.guide_form.invalid) {
	    	return; // You will get form value if your form is valid
	                //var formValues = this.guide_form.getRawValue;
	    }

	    let status = '0';
	    if(this.guide_form.controls['status'].value){
	    	status = '1';
	    }

        this.showLoader();
	    let body = new FormData();
        body.append('name', this.guide_form.controls['name'].value);
        body.append('email', this.guide_form.controls['email'].value);
        body.append('document', this.guide_form.controls['document'].value);
        body.append('phone', this.guide_form.controls['phone'].value);
        body.append('status', status);

        this.service.queryPost('guides', body).subscribe(
            response=>
            {      
                this.hideLoader();
                let result = response.json();

                if(result.message === 'ya registrado'){
                    let message = 'El documento ya esta registrado';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
                
                if(result.success){
                    this.getGuides(this.currentPage);
                    this.view = 'index';
                    this.router.navigate(['/guides/index']);  
                    this.toastr.success(result.message);
                    this.guide_form.reset();
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );  
	};


	edit(guideId)
    {    
        this.service.queryGet('guides/'+guideId).subscribe(
            response=>
            {      
                let result = response.json(); 
                let guide = result;
                this.guide_id = guide.id;
                this.guide_form.controls['id'].setValue(guide.id);
                this.guide_form.controls['name'].setValue(guide.name);
                this.guide_form.controls['email'].setValue(guide.email);
                this.guide_form.controls['document'].setValue(guide.document);
                this.guide_form.controls['phone'].setValue(guide.phone);
               
                if(guide.status == 1){
                	this.guide_form.controls['status'].setValue(true);
                }
                else{
                	this.guide_form.controls['status'].setValue(false);	
                }
                this.view = 'form';
                this.router.navigate(['/guides/form']);  
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );
    }

	update()
    {    
        this.showLoader();
    	let status = '0';
	    if(this.guide_form.controls['status'].value){
	    	status = '1';
	    }
	    
        let id = this.guide_id;
        
        let body = new FormData();
        body.append('_method', 'PUT');
        body.append('id', this.guide_id+'');
        body.append('name', this.guide_form.controls['name'].value);
        body.append('email', this.guide_form.controls['email'].value);
        body.append('document', this.guide_form.controls['document'].value);
        body.append('phone', this.guide_form.controls['phone'].value);
        body.append('status', status);

        this.service.queryPost('guides/'+id+'', body).subscribe(
            response=>
            {    
                this.hideLoader();  
                let result = response.json();
                 if(result.success){
                    this.getGuides(this.currentPage);
                    this.view = 'index';
                    this.guide_id = 0;
                    this.toastr.success(result.message);
                    this.guide_form.reset();
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        ); 
    }

    deleteGuide(guideId)
    {
        this.service.queryDelete('guides/'+guideId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list guides
                    this.getGuides(this.currentPage);
                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );
    }

    confirmDelete(guideId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteGuide(guideId);
            }
        });
    }
}
