import { Component, isDevMode, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  	selector: 'app-root',
  	templateUrl: './app.component.html',
  	styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  	title = 'app';

  	constructor(private translate: TranslateService) 
    {
        translate.setDefaultLang('es');
        translate.use('es');
    }

    useLanguage(language: string) 
    {
        this.translate.use(language);
    }

    ngOnInit() {
        if (isDevMode()) {
          console.log('Development!');
        } else {
          console.log('Production!');
        }
      }

}
