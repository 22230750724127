import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../session.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	public user_form: FormGroup;
	public user = {};
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public cropperReady = false;

  	constructor(private translate: TranslateService,
                public service: ApirestService,
                public singleton: SingletonService,
                private session: SessionService,
                private toastr: ToastrService,
                fb: FormBuilder) 
  	{
  		this.user_form = fb.group({
            'name': ['', Validators.compose([Validators.required, Validators.minLength(6)])],
            'email': ['', Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+@\w+\.\w{2,3})$/)])],
            'password': ['', Validators.compose([Validators.minLength(8)])],
            'confirm_password': ['', Validators.compose([Validators.minLength(8)])]
        });
  	}

  	ngOnInit() 
  	{
  		//se valida la session
        this.singleton.getUserSession();
        //se trae el usuario en session
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
  				this.user_form.controls['name'].setValue(this.user['name']);
                this.user_form.controls['email'].setValue(this.user['email']);
                this.user_form.controls['password'].setValue('');
                this.user_form.controls['confirm_password'].setValue('');
                this.translate.use(this.user['language']);
            }
        });
  	}

  	fileChangeEvent(event: any): void 
  	{
    	this.imageChangedEvent = event;
	}

	imageCropped(image: string) 
	{
	    this.croppedImage = image;
	}

	imageLoaded() 
	{
	    this.cropperReady = true;
	}

	loadImageFailed() 
	{
	    // show message
	}

	uploadImage()
	{
		document.getElementById('upload-image').click();
	}

	/**
	 * Saves the user profile
	 */
	saveProfile()
	{	
		for (let v in this.user_form.controls) {
          this.user_form.controls[v].markAsTouched();
        }
        console.log(this.user_form.controls);
        if (this.user_form.invalid) {
            return;
        }

		var body = new FormData();

		if('' != this.croppedImage)
		{
			var block = this.croppedImage.split(";");
			// Get the content type of the image
			var contentType = block[0].split(":")[1];// In this case "image/gif"
			// get the real base64 content of the file
			var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

			// Convert it to a blob to upload
			var blob = this.singleton.b64toBlob(realData, contentType);

			// Create a FormData and append the file with "image" as parameter name
			body.append("image", blob, 'image.jpg');
		}

		body.append('name', this.user_form.controls['name'].value);
        body.append('email', this.user_form.controls['email'].value);
        body.append('password', this.user_form.controls['password'].value);
		body.append('confirm_password', this.user_form.controls['confirm_password'].value);

		this.service.queryPost('update-profile', body).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                	this.user = result.user;
                	localStorage.setItem('user', JSON.stringify(result.user));
                	this.imageChangedEvent = '';
					this.croppedImage = '';
					this.cropperReady = false;
                    //Show success message
                    this.toastr.success(result.message);
                }
                else
                {
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }

                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err => 
            {
                console.log(err);
            }
        );
	}

	/**
	 * Cancel the cropped image
	 */
	cancelUpload()
	{
		this.imageChangedEvent = '';
		this.croppedImage = '';
		this.cropperReady = false;
	}
}
