import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { SingletonService } from '../singleton.service';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import * as jquery from 'jquery';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

@HostListener("window:scroll", ['$event'])

export class LandingComponent implements OnInit {

	public services = [];
	public bedrooms = [];
    public rows = 3;
    public button_text = 'Ver más';
	user_form: FormGroup;
  	checkboxValue: any;

    //Menu variables  
  	public isCollapsed = true;
	private menuXs = false;	
	public option = 'home'; //Default option menu
    public block = 'nueva'; //Default accommodation
    public loader = false;

	//Contact form variables
	public email = '';
	public message = '';
	public subject = '';
    public conditions = true;

    //Bedrooms images 
    public newHouseImages = [];
    public oldHouseImages = [];
    public showIndicator = false;

    @ViewChild('modal') modal;
    @ViewChild('modalVideo') modalVideo;
    modalRef: BsModalRef;
        config = {
        animated: true
    };
    modalRefVideo: BsModalRef;
    
    public url;

	doSomethingOnWindowsScroll($event:Event)
	{
		let scrollOffset = jquery("html,body").scrollTop();
		var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if(is_safari)
		{
		 scrollOffset = jquery("body").scrollTop();
		}

		if(320 < scrollOffset)
		{
		 jquery('#menu').addClass('nav-fix');
         jquery('#top').removeClass('d-none');
		}
		else
		{
		 jquery('#menu').removeClass('nav-fix');
         jquery('#top').addClass('d-none');
		}

		if(250 < scrollOffset)
		{
		 jquery('#menu-xs').addClass('nav-fix');
		}
		else
		{
		 jquery('#menu-xs').removeClass('nav-fix');
		}
	}

    constructor(public service: ApirestService,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private pageScrollService: PageScrollService,
                private modalService: BsModalService,
                private sanitizer: DomSanitizer,
                @Inject(DOCUMENT) private document: any,
                fb: FormBuilder) 
    { 
    	this.user_form = fb.group({
	    	'name': ['',  Validators.compose([Validators.required, Validators.minLength(6)])],
	    	'email': ['', Validators.compose([Validators.required, Validators.email])],
	    	'text': ['',  Validators.compose([Validators.required, Validators.minLength(10)])]
	    });
    }

    ngOnInit() {
    	this.getServices();
    	this.getBedrooms();
    	this.pageScrollService.scroll({
		    document: this.document,
		    scrollTarget: '.theEnd',
		});

        //<iframe width="560" height="315" src="https://www.youtube.com/embed/58yUdL0jNhw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        this.url = this.getVideo('https://www.youtube.com/embed/58yUdL0jNhw');

        //CAMBIO EN TRADUCCIÓN DE BOTÓN 
        this.viewServices();
    }

    getVideo(url)
    {    
        //let video = url.replace("watch?v=", "embed/");
        url+='?autoplay=1&rel=0';
        let video = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        return video;
    }

    /**
     * Obtains the list of active services 
     */
    getServices(){
    	let url = 'landing-services?rows=' + this.rows;

        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.services = result.data;
                this.translateServices(this.services);
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    /**
     * Obtains the list of active bedrooms 
     */
    getBedrooms(){
    	let url = 'active-bedrooms';

        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.bedrooms = result.bedrooms;
                this.newHouseImages = result.newHouseImages;
                this.oldHouseImages = result.oldHouseImages;
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    /**
     * Send mail of contact form
     */
    sendMail(){
    	for (let v in this.user_form.controls) {
	      this.user_form.controls[v].markAsTouched();
	    }

	    if (this.user_form.invalid) {
	    	return; // You will get form value if your form is valid
	                //var formValues = this.service_form.getRawValue;
	    }

        this.loader = true;
	    let body = new FormData();
        body.append('name', this.user_form.controls['name'].value);
        body.append('email', this.user_form.controls['email'].value);
        body.append('text', this.user_form.controls['text'].value);
        this.service.queryPostRegular('contact-mail', body).subscribe(
            response=>
            {      
                this.loader = false;
                let result = response.json();
                if(result.success){
                    this.toastr.success(result.message);
                	this.user_form.reset();
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.loader = false;
                //console.log(err);
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
            }
        );  	
    }

    /**
     * Scroll to selected section
    */
    scrollTo(section)
  	{	
  		this.option = section;
  		this.pageScrollService.scroll({
		    document: this.document,
		    scrollTarget: section,
		});
    }

    /**
     * Redirect to detail service
     */  
    openService(serviceId)
    {    
        this.router.navigate(['/service/'+serviceId]);
    }

    /**
     * Obtains the images of selected block (Casa nueva, casa ivernadero)
     */
    selectBlock(block)
    {
        this.block = block;
    }

    acceptConditions()
    {
        if(this.conditions){
            this.conditions = false;
        }
        else{
            this.conditions = true;
        }
    }

    openModal() {
        this.modalRef = this.modalService.show(this.modal, {class: 'modal-lg', ignoreBackdropClick: true});
    }

    viewServices()
    {
        if(3 == this.rows){
            this.rows = 10;
            this.getServices();
            this.button_text = 'landing.seeLess';
        }
        else{
            this.rows = 3;
            this.getServices();
            this.button_text = 'landing.seeMore';
        }
    }

    openVideo(template){
        this.modalRefVideo = this.modalService.show(template, {class: 'modal-lg', ignoreBackdropClick: true});
    }

/***
 * Descripcion: Este mètodo realiza la conversion de los diferentes servicios segùn el lenguaje utilizado en el navegador
 *  Titulo: translateSevice()
 * Autor: Maria Fernanda
 */
    translateServices(services){
        services.forEach(service => {
                if(service.name=='Sendero'){
                    service.name='landing.Sendero';
                } else if(service.name=='Avistamiento diurno'){
                    service.name='landing.AvistamientoDiurno';
                }else if(service.name=='Hospedaje'){
                    service.name='landing.Hospedaje';
                }
            }
        );
    }
}