import { Component, OnInit } from '@angular/core';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import Chart from 'chart.js';
defineLocale('es', esLocale); 

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

	public auxServices = [];
	public services = [];
	public auxCountries = [];
	public countries = [];
	public auxInvoices = [];
	public invoices = [];
	public date = '';
	public type = 1;
	form: FormGroup;
	locale = 'es';
	public selectedServices = []; 
	public selectedCountries = []; 
	public selectedInvoices = []; 
	public showResults = false;
	public billingReservations = [];
	public visitants = [];
	public exportExcel = 0;

	//Variables to show when the report type is 1 o 2
	public generalServices = [];
	public nationalServices = [];
	public internationalServices = [];
	public generalCountries = [];
	public totalGeneralServices = 0;
	public totalVisitantsServices = 0;
	public totalNationalServices = 0;
	public totalInternationalServices = 0;
	public listCountries = [];
	public totalCountries = 0;
	public reservations = []; //Reservas anuladas

	//Variables to generate chart
	public data = {};
	public options = {}
	public reportType = '';
	public showCountriesChart = true;
	public showCountriesChartGeneral = true;
	public showServicesChart = true;

 	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                fb: FormBuilder,
                private localeService: BsLocaleService) 
 	{ 
 		this.localeService.use(this.locale);
 	}

  	ngOnInit() {
  		this.getCountries();
  		this.getServices();
  		this.getInvoices();
  	}

  	showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
       setTimeout(()=>{s.spinner.hide()},500);
    }

  	getCountries()
  	{
  		let url = 'countries';

        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.countries = result;
            },
            err => 
            {
                
            }
        );
  	}

  	getServices()
  	{
  		let url = 'services';

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.services = result;
            },
            err => 
            {
                
            }
        );
  	}

  	getInvoices()
  	{
  		let url = 'invoices';

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.invoices = result;
            },
            err => 
            {
                
            }
        );
  	}

  	onServiceSelect(item:any)
	{
		let service = item;
		if(null != service)
		{
			this.auxServices.push(service.id);
		}
	}

	onCountrySelect(item:any)
	{
		let country = item;
		if(null != country)
		{
			this.auxCountries.push(country.name);
		}
	}

  	onSelectAll() 
  	{
		if(this.selectedServices.length == this.services.length){
			this.selectedServices = [];
			this.auxServices = [];
		} else {
			this.selectedServices = this.services;
			this.auxServices = [];
			for (var i = 0; i < this.services.length; ++i) {
				this.auxServices.push(this.services[i].id);
			}
		}
    }

    selectAllCountries() 
    {
		if(this.selectedCountries.length == this.countries.length){
			this.selectedCountries = [];
			this.auxCountries = [];
		} else {
			this.selectedCountries = this.countries;
			this.auxCountries = [];
			for (var i = 0; i < this.countries.length; ++i) {
				this.auxCountries.push(this.countries[i].name);
			}
		}
    }

    onInvoiceSelect(item:any)
	{
		let invoice = item;
		if(null != invoice)
		{
			this.auxInvoices.push(invoice.id);
		}
	}

    selectAllInvoices() 
    {
		if(this.selectedInvoices.length == this.invoices.length){
			this.selectedInvoices = [];
			this.auxInvoices = [];
		} else {
			this.selectedInvoices = this.invoices;
			this.auxInvoices = [];
			for (var i = 0; i < this.invoices.length; ++i) {
				this.auxInvoices.push(this.invoices[i].id);
			}
		}
    }

    searchService(search)
	{
		let aux_search = search.term;
		/*this.search = aux_search;
		this.getServices();*/
	}	

	removeService(item:any)
	{	
		let service = item;
		if(null != service){
			let id = service.value.id;
			let index = this.auxServices.findIndex(x => x === id);
	        this.auxServices.splice(index, 1);
		}
	}

	changeType()
	{
		this.type = this.type;
		this.clearResults();
		/*this.showCountriesChart = true;
		this.showCountriesChartGeneral = true;
		this.showServicesChart = true;
		this.showResults = false;*/
	}

	clearResults()
	{
		this.generalServices = [];
		this.nationalServices = [];
		this.internationalServices = [];
		this.generalCountries = [];
		this.totalGeneralServices = 0;
		this.totalVisitantsServices = 0;
		this.totalNationalServices = 0;
		this.totalInternationalServices = 0;
		this.listCountries = [];
		this.billingReservations = [];
		this.showCountriesChart = true;
		this.showServicesChart = true;
		this.showCountriesChartGeneral = true;
	}

	maxValue(items: Array<number>)
    {
        let value = 0;
        if(items.length == 0)
        {
            return 1;
        } else {
            for (var i = 0; i < items.length; i++)
            {
                if(items[i] > value)
                {
                    value = items[i];
                }    
            }
            
            return  value + 2;
        }
    }

    generateColor()
    {
    	let hexadecimal = new Array("0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F")
		let color = "#";
		for (let i=0;i<6;i++){
		  let posarray = this.random(0,hexadecimal.length)
		  color += hexadecimal[posarray]
		}
		return color;
    }

    random(inferior,superior){
	   let numPosibilidades = superior - inferior
	   let aleat = Math.random() * numPosibilidades
	   aleat = Math.floor(aleat)
	   return parseInt(inferior) + aleat
    }

    

	getReport(exportExcel)
	{	
		this.showLoader();
		this.clearResults();
		let start = '';
		let end = '';
		if(this.date != undefined && this.date != '')
        {
            start = moment(this.date[0]).format("YYYY-MM-DD");
            end = moment(this.date[1]).format("YYYY-MM-DD");
        }

		let services = JSON.stringify(this.auxServices);
		let countries = JSON.stringify(this.auxCountries);
		let invoices = JSON.stringify(this.auxInvoices);
		let url = 'report?type=' + this.type + '&start=' + start + '&end=' + end;
		if(this.type < 4){
			url += '&services='+ services;
		}
		else if(this.type == 4){
			url += '&countries=' + countries;
		}
		else if(this.type == 5){
			url += '&invoices=' + invoices;
		}

		url += '&export=' + exportExcel;

		this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                //console.log(result);
            	if(result.success){
            		if(this.type < 4)
            		{
        				this.generalServices = result.data['general'];
        				this.totalGeneralServices = result.data['totalVisitants'];
        				this.totalVisitantsServices = result.data['totalVisitantsServices'];
        				this.totalNationalServices = result.data['totalNationals'];
        				this.totalInternationalServices = result.data['totalInternationals'];

        				//Participacion procedencia
            			let labels = ['Nacionales', 'Extranjeros'];
                    	let dataSets = [];
        				
                    	//Participacion por servicio
        				let participation = [];
		                let labels2 = [];
		                let dataSets2 = [];
		                let backgrounds = [];


        				let generalServices = result.data['general'];
        				if(generalServices.length > 0)
        				{
        					for (var i = 0; i < generalServices.length ; i++){

        						//Gráfica de participación por procedencia
        						let service = {name: '', national:0 , international: 0, total: 0};
        						let national = generalServices[i].national_visitors;
        						let international = generalServices[i].international_visitors;
        						if(national == null)
        						{
        							national = 0;
        						}

        						if(international == null)
        						{
        							international = 0;
        						}
        						let total = parseInt(national) + parseInt(international);
        						service = {name: generalServices[i].name, national: national, international: international , total: total};
        						let background = this.generateColor();
		        				let totals = [national, international];
		        				let dataSet = {label: this.generalServices[i].name, backgroundColor: background, data: totals};
		        				dataSets.push(dataSet);
		        				this.generalServices[i].quantity = total;


		        				//Gráfica participación del servicio
		        				let percentage = ((this.generalServices[i].quantity * 100) / this.totalVisitantsServices);
		        				let dataParticipation = {name: this.generalServices[i].name, total_services: percentage};
		        				participation.push(dataParticipation);
        					}
        				}


        				//Gráfica de participacion por procedencia
    					this.reportType = 'bar';
	                    this.data = {
	                    	labels: labels,
	                      	datasets: dataSets
	                    };
    					this.options = {
	                      responsive: true,
	                      scales: {
					        yAxes: [{
					            ticks: {
					                beginAtZero: true,
					                userCallback: function(label, index, labels) {
					                     // when the floored value is the same as the value we have a whole number
					                     if (Math.floor(label) === label) {
					                         return label;
					                     }

					                },
					            }
					        }]
					      }
	                    };
            			
            			
            			//Grafica participación del servicio
	                    for (var i = 0; i < participation.length; i++) 
	                    {
	                        labels2.push(participation[i].name);
	                        dataSets2.push(participation[i].total_services);
	                        let color = this.generateColor();
	                        backgrounds.push(color);
	                    }
	                    new Chart(document.getElementById("chart"), {
						    type: 'bar',
						    data: {
						      labels: labels2,
						      datasets: [
						        {
						          label: "Participacion del servicio",
						          backgroundColor:backgrounds,
						          data: dataSets2
						        }
						      ]
						    },
						    options: {
						      legend: { display: false },
						      title: {
						        display: true,
						        text: 'Participacion del servicio'
						      },
						      scales: {
						        yAxes: [{
						            ticks: {
						                beginAtZero: true,
						                userCallback: function(label, index, labels) {
						                    // when the floored value is the same as the value we have a whole number
						                    if (Math.floor(label) === label) {
						                        return label;
						                    }
						                },
						            }
						        }]
						      }
						    }
						});
						this.showServicesChart = false;
						

						//Grafica visitantes por país
						let data3 = result.data['visitants'];
            			this.listCountries = data3;
            			this.totalCountries = result.data['totalVisitants'];
		                let labels3 = [];
		                let dataSets3 = [];
		                let backgrounds3 = [];
	                    for (var i = 0; i < data3.length; ++i) 
	                    {
	                        labels3.push(data3[i].nationality);
	                        dataSets3.push(data3[i].total);
	                        let color = this.generateColor();
	                        backgrounds3.push(color);
	                    }

		                if(data3.length > 0){    
		                    new Chart(document.getElementById("general-countries-chart"), {
							    type: 'bar',
							    data: {
							      labels: labels3,
							      datasets: [
							        {
							          label: "Visitantes",
							          backgroundColor:backgrounds3,
							          data: dataSets3
							        }
							      ]
							    },
							    options: {
							      legend: { display: false },
							      title: {
							        display: true,
							        text: 'Visitantes por pais'
							      },
							      scales: {
								        yAxes: [{
								            ticks: {
								                beginAtZero: true,
								                userCallback: function(label, index, labels) {
							                    	// when the floored value is the same as the value we have a whole number
							                    	if (Math.floor(label) === label) {
							                        	return label;
							                     	}
							                	},
								            }
								        }]
								    }
							    }
							});
							this.showCountriesChartGeneral = false;
						}


					} 
            		else if(4 == this.type)
            		{
            			let data = result.data['visitors'];
            			this.listCountries = data;
		                let labels = [];
		                let dataSets = [];
		                let backgrounds = [];
	                    for (var i = 0; i < data.length; ++i) 
	                    {
	                        labels.push(data[i].nationality);
	                        dataSets.push(data[i].total);
	                        let color = this.generateColor();
	                        backgrounds.push(color);
	                        this.totalGeneralServices += parseInt(data[i].total);
	                    }

		                if(data.length > 0){    
		                    new Chart(document.getElementById("countries-chart"), {
							    type: 'bar',
							    data: {
							      labels: labels,
							      datasets: [
							        {
							          label: "Visitantes",
							          backgroundColor:backgrounds,
							          data: dataSets
							        }
							      ]
							    },
							    options: {
							      legend: { display: false },
							      title: {
							        display: true,
							        text: 'Visitantes por pais'
							      },
							      scales: {
								        yAxes: [{
								            ticks: {
								                beginAtZero: true,
								                userCallback: function(label, index, labels) {
							                    	// when the floored value is the same as the value we have a whole number
							                    	if (Math.floor(label) === label) {
							                        	return label;
							                     	}
							                	},
								            }
								        }]
								    }
							    }
							});
							this.showCountriesChart = false;
							this.showServicesChart = true;
						}
            		}
            		else if(5 == this.type)
            		{
            			this.billingReservations = result.data['reservations'];
            		}
            		else if(6 == this.type)
            		{
            			this.visitants = result.data['visitants'];
            		}	
            		else if(7 == this.type)
            		{
            			this.reservations = result.data['reservations'];
            		}

            		this.showResults = true;
            		if(1 == exportExcel)
            		{
            			window.open(result.data['report'], '_blank');
            		}
            		this.hideLoader();
            	}
                else
                {	
                	this.hideLoader();
                	this.showResults = false;
                	this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }	
            },
            err => 
            {	
            	this.hideLoader();
            	this.showResults = false;
                let result = err.json();
                this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );	    
	}
}
