import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from '../session.service';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-accommodation',
  templateUrl: './accommodation.component.html',
  styleUrls: ['./accommodation.component.scss']
})
export class AccommodationComponent implements OnInit {

    //Form variables
  	public accommodation_id = 0;
	accommodation_form: FormGroup;
  	checkboxValue: any;
    public images = [];

    //List variables
  	public accommodations = [];
    public currentPage = 0;
  	public pages = [];
    public rows = 10;
    public search = '';
  	public view = 'index';

    //Drag and drop variables  
    url_uploads = '';
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;

    private user = {name:'', url_image:''};
    public permissions = [];

	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                public session: SessionService,
                public route: ActivatedRoute,
                private router: Router,
                fb: FormBuilder) 
	{     
        this.route.params.subscribe(params => {
            this.view = params['view'];
        }); 

		this.accommodation_form = fb.group({
	    	'number': ['', Validators.required],
	    	'number_single_beds': ['', Validators.required],
	    	'number_double_beds': ['', Validators.required],
	    	'bathroom_type': ['', Validators.required],
	    	'full_rate': ['', Validators.required],
	    	'single_rate': ['', Validators.required],
	    	'block': ['', ],
	    	'status': [true, Validators.required]
	    });	
        this.options = { concurrency: 1, maxUploads: 20 };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
        this.url_uploads = this.service.apiUrl  + 'save-image';
	}

	ngOnInit() {
        this.getUser();
  		this.getAccommodations('');
  	}

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });
    }  

    /**
     * Obtains the list of data with the paging information and search results
     */ 
  	getAccommodations(page)
  	{
  		let url = 'bedrooms?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.accommodations = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                //console.log(err);
            }
        );
  	}

    /**
     * Calls the elements of the given page
     * @param int page Next page to paginate
     */
    getPage(page)
    {    
        if(page != this.currentPage)
        {
            this.getAccommodations(page);
        }    
    }

    /**
     * Reset accommodation form, change active section
     * @param string view Section to show
     */
  	changeView(view){
        this.router.navigate(['/accommodation/'+view]);  
  		this.view = view;
  		this.accommodation_form.reset();
        this.images = [];
  	}

    /**
     * Show spinner while loading information
     */  
    showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    /**
     * Hide spinner after loading information
     */ 
    hideLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.hide()},500);
    }  	

    /**
     * Function that save an accommodation
     */
  	submitForm() {
	    for (let v in this.accommodation_form.controls) {
	      this.accommodation_form.controls[v].markAsTouched();
	    }
        
	    if (this.accommodation_form.invalid) {
	    	return;
	    }
	    
	    let status = '0';
	    if(this.accommodation_form.controls['status'].value){
	    	status = '1';
	    }

        this.showLoader();
	    let full_rate = this.accommodation_form.controls['full_rate'].value.replace(/\,/g,'');
	    let single_rate = this.accommodation_form.controls['single_rate'].value.replace(/\,/g,'');

	    let body = new FormData();
        body.append('number', this.accommodation_form.controls['number'].value);
        body.append('number_single_beds', this.accommodation_form.controls['number_single_beds'].value);
        body.append('number_double_beds', this.accommodation_form.controls['number_double_beds'].value);
        body.append('bathroom_type', this.accommodation_form.controls['bathroom_type'].value);
        body.append('full_rate', full_rate);
        body.append('single_rate', single_rate);
        body.append('block', this.accommodation_form.controls['block'].value);
        body.append('status', status);

        this.service.queryPost('bedrooms', body).subscribe(
            response=>
            {     
                this.hideLoader(); 
                let result = response.json();
                if(result.success){
                    this.view = 'index';
                    this.router.navigate(['/accommodation/index']);  
                    this.toastr.success(result.message);
                    this.accommodation_form.reset();
                    this.getAccommodations(this.currentPage);
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
            }
        ); 

	};

    /**
     * It searches the accommodation and set to global accommodation variable to edit
     * @param int accomodationId to edit a register
     */
	edit(accommodationId)
    {    
        this.showLoader();
        this.service.queryGet('bedrooms/'+accommodationId).subscribe(
            response=>
            {      
                let result = response.json(); 
                let accommodation = result;
                this.accommodation_id = accommodation.id;
                this.accommodation_form.controls['number'].setValue(accommodation.number);
                this.accommodation_form.controls['number_single_beds'].setValue(accommodation.number_single_beds);
                this.accommodation_form.controls['number_double_beds'].setValue(accommodation.number_double_beds);
                this.accommodation_form.controls['bathroom_type'].setValue(accommodation.bathroom_type);
                this.accommodation_form.controls['full_rate'].setValue(accommodation.full_rate);
                this.format('', 'full_rate');
                this.accommodation_form.controls['single_rate'].setValue(accommodation.single_rate);
                this.format('', 'single_rate');
                this.accommodation_form.controls['block'].setValue(accommodation.block);
                if(accommodation.status == 1){
                	this.accommodation_form.controls['status'].setValue(true);
                }
                else{
                	this.accommodation_form.controls['status'].setValue(false);	
                }
                this.view = 'form';
                this.router.navigate(['/accommodation/form']);  
                this.getImages(accommodationId);
                this.hideLoader();
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
            }
        );
    }

    /**
     * Function that update an accommodation
     */
    update()
    {    
        this.showLoader();
    	let status = '0';

	    if(this.accommodation_form.controls['status'].value){
	    	status = '1';
	    }

	    let full_rate = this.accommodation_form.controls['full_rate'].value.replace(/\,/g,'');
	    let single_rate = this.accommodation_form.controls['single_rate'].value.replace(/\,/g,'');
	    
        let id = this.accommodation_id;
        let body = new FormData();
        body.append('_method', 'PUT');
        body.append('number', this.accommodation_form.controls['number'].value);
        body.append('number_single_beds', this.accommodation_form.controls['number_single_beds'].value);
        body.append('number_double_beds', this.accommodation_form.controls['number_double_beds'].value);
        body.append('bathroom_type', this.accommodation_form.controls['bathroom_type'].value);
        body.append('full_rate', full_rate);
        body.append('single_rate', single_rate);
        body.append('block', this.accommodation_form.controls['block'].value);
        body.append('status', status);

        this.service.queryPost('bedrooms/'+id, body).subscribe(
            response=>
            {      
                this.hideLoader();
                let result = response.json();
                if(result.success){
                    this.view = 'index';
                    this.toastr.success(result.message);
                    this.accommodation_form.reset();
                    this.accommodation_id = 0;
                    this.getAccommodations(this.currentPage);
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        ); 
    }

    /**
     * Deletes the accommodation
     * @param string accommodationId id accommodation to delete
     */
    deleteAccommodation(accommodationId)
    {
        this.service.queryDelete('bedrooms/'+accommodationId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list accommodations
                    this.getAccommodations(this.currentPage);
                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                } else {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        );
    }

    /**
     * Ask for confirmation before to delete the accommodation
     * @param string accommodationId id accommodation to delete
     */
    confirmDelete(accommodationId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteAccommodation(accommodationId);
            }
        });
    }

    /**
     * Change format of single and full rate
     */ 
    format(event, type = '')
	{	
		let name = type;
		if('' == name){
	    	name = event.target.attributes['formcontrolname'].nodeValue;
	    }

	    let num = this.accommodation_form.controls[name].value.replace(/\,/g,'');
	    
	    if(!isNaN(parseInt(num))){
	      num = num.toString().split('').reverse().join('').replace(/(?=\d*\,?)(\d{3})/g,'$1,');
	      num = num.split('').reverse().join('').replace(/^[\,]/,'');
	       this.accommodation_form.controls[name].setValue(num);
	    }
	    else{ alert('Solo se permiten numeros');
	      this.accommodation_form.controls[name].setValue(this.accommodation_form.controls[name].value.replace(/[^\d\,]*/g,''));
	    }
	}

    /** Upload images on accommodation */
    onUploadOutputFile(output: UploadOutput): void {
        
        let token = 'Bearer '+ sessionStorage.getItem('sid').substring(6, sessionStorage.getItem('sid').length);
        if (output.type === 'allAddedToQueue') {
            const event: UploadInput = {
                type: 'uploadAll',
                url: this.url_uploads,
                headers: ({"Authorization": token}),
                method: 'POST',
                data: { 
                    ubication_id: this.accommodation_id+'',
                    ubication: '2',
                    image: output.file+''
                }
            };
            this.uploadInput.emit(event);
        } else if (output.type == 'addedToQueue' && typeof output.file != 'undefined') {
          this.files.push(output.file);
        } else if (output.type == 'uploading' && typeof output.file != 'undefined') {
          const index = this.files.findIndex(file => typeof output.file != 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        } else if (output.type == 'cancelled' || output.type == 'removed') {
          this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type == 'dragOver') {
          this.dragOver = true;
        } else if (output.type == 'dragOut') {
          this.dragOver = false;
        } else if (output.type == 'drop') {
          this.dragOver = false;
        } else if (output.type == 'rejected' && typeof output.file !== 'undefined') {
          //console.log(output.file.name + ' rejected');
        } else if (output.type == 'done'){
            let response;
            response = output.file.response;
            if(response.success)
            {    
                this.toastr.success(response.message);
                this.getImages(this.accommodation_id);
            }
            else
            {    
                this.toastr.error(response.message,'Error');
            }
        }
        this.files = this.files.filter(file => file.progress.status != UploadStatus.Done);
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    /**
     * Obtains the images of accommodation
     * @param string accommodationId id accommodation to show
     */ 
    getImages(accommodationId)
    {
        let url = 'show-image/' + this.accommodation_id + '?ubication=' + 2;
        
         this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.images = result;
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    /**
     * Deletes the image of accommodation
     * @param string imageId id image to delete
     */
    deleteImage(imageId)
    {
       this.service.queryGet('delete-image/'+imageId).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.success)
                {
                    //Reload the list images
                    this.getImages(this.accommodation_id);
                    this.toastr.success(result.message);
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error intenta mas tarde';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'}); 
            }
        ); 
    }
}
