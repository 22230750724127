import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApirestService } from '../apirest.service';
import { SingletonService } from '../singleton.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

	public serviceId = 0;
	public isCollapsed = true;
	private menuXs = false;
	public aux_service = {id: 0, name:'', description:'', image:'', person_rate:0, general_rate:0};
    public images = [];
    public showIndicator = true;
    public option = '';

  	constructor(private translate: TranslateService,
    			public service: ApirestService,
                private router: Router,
                public singleton: SingletonService,
                public route: ActivatedRoute) { }

  	ngOnInit() {
  		this.route.params.subscribe(params => {
            this.serviceId = params['serviceId'];
            window.scroll(0,0);
        });
  		this.getService(this.serviceId);
        this.getImages(this.serviceId);
  	}

  	getService(serviceId)
  	{
  		let url = 'service/'+ serviceId;

	  	this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.aux_service['id'] = result.id;
                this.aux_service['name'] = result.name;
                this.aux_service['description'] = result.description;
                this.aux_service['image'] = result.image;
                this.aux_service['cover'] = result.cover;
                this.aux_service['person_rate'] = result.person_rate;
                this.aux_service['general_rate'] = result.general_rate;
                this.translateService(this.aux_service);
            },
            err => 
            {
                
            }
        );    
  	}

    getImages(accommodationId)
    {
        let url = 'show-image/' + this.serviceId + '?ubication=' + 1;
        
        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.images = result;
            },
            err => 
            {
                
            }
        );
    }

    translateService(aux_service){
        //let language= window.navigator.language;
        if(aux_service!=null){
            //if (language=='en') {
                if ( aux_service['name'] == 'Sendero') {
                    aux_service['name'] ='landing.Sendero';
                    aux_service['description'] = 'services.textSendero';
                } else if(aux_service['name']=='Avistamiento diurno'){
                    aux_service['name']='landing.AvistamientoDiurno';
                    aux_service['description'] = 'services.textAvistamiento';
                }else if(aux_service['name']=='Hospedaje'){
                    aux_service['name']='landing.Hospedaje';
                    aux_service['description'] = 'services.textHospedaje';
                }
           // }
        }
    }
}
