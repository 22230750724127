import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionService } from '../session.service';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

	//List variables
  	public messages = [];
    public currentPage = 0;
  	public pages = [];
    public rows = 10;
    public search = '';
    public message = {name:'', email:'', content:'', created_at:''};

    @ViewChild('modal') modal;
    modalRef: BsModalRef;
        config = {
        animated: true
    };

  	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                public session: SessionService,
                public route: ActivatedRoute,
                private modalService: BsModalService,
                private router: Router) { }

  	ngOnInit() {
  		this.getMessages('');
  	}

  	getMessages(page)
  	{
  		let url = 'contact-messages?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.messages = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                //console.log(err);
            }
        );
  	}


  	/**
     * Calls the elements of the given page
     * @param int page Next page to paginate
     */
    getPage(page)
    {    
        if(page != this.currentPage)
        {
            this.getMessages(page);
        }    
    }

    showMessage(messageId)
    {
    	let url = 'show-message/' + messageId;
    	this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.message['name'] = result.name;
                this.message['email'] = result.email;
                this.message['content'] = result.content;
                this.message['created_at'] = result.created_at;
                this.modalRef = this.modalService.show(this.modal, {class: 'modal-md', ignoreBackdropClick: true});
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    closeModal()
    {	
    	this.modalRef.hide();
    	this.message = {name:'', email:'', content:'', created_at:''};
    }

}
