import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { TranslateService } from '@ngx-translate/core';
import { ApirestService } from '../apirest.service';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { SessionService } from '../session.service';
import { SingletonService } from '../singleton.service';

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from "date-fns";
import { Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { CalendarEvent, CalendarDateFormatter, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, DAYS_OF_WEEK, CalendarMonthViewDay  } from 'angular-calendar';
import localeEs from '@angular/common/locales/es';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
registerLocaleData(localeEs);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public services = [];
    //Data reservations
    public lastReservations = [];
    public reservations = [];
    public pages = [];
    public currentPage = 0;
    public search = '';
    public rows = 10;

    //Calendar configuration
	locale = 'es';
    view: string = 'month';
    viewDate: Date = new Date();
    events: CalendarEvent[] = [];
    clickedDate: Date;
    clickedColumn: number;

    CalendarView = CalendarView;
    activeDayIsOpen: boolean = false;

  	constructor(private translate: TranslateService,
                public service: ApirestService,
                public session: SessionService,
                private singleton: SingletonService,
                private router: Router) { }

  	ngOnInit() {
  		let today = new Date();
  		this.getReservations('', today);
        this.getDataMonth(today);  
        sessionStorage.setItem('reservation_id', '0');
  	}

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            this.viewDate = date;
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || 
              events.length === 0) 
            {
                this.activeDayIsOpen = true;
            } else {
                this.activeDayIsOpen = true;
            }
        }
        let tempDate = moment(date).format('YYYY-MM-DD');
        this.getReservations('', tempDate);
    }

    getReservations(page, date)
    {	
        let aux_date = moment(date).format('YYYY-MM-DD');
    	let url = 'reservations?rows=' + this.rows;
        url += '&search=' + this.search + '&date=' + aux_date;

        if(page != '')
        {
            url += '&page=' + page;            
        }

    	this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.reservations = result.data;
            },
            err => 
            {
                //console.log(err);
            }
        );
    }

    getDataMonth(month)
    {    
        let aux_month = moment(month).format('YYYY-MM-DD');
        let url = 'reservations-data-month?month=' + aux_month;
        this.service.queryGet(url).subscribe(
             response=>
            {      
                let result = response.json();
                this.services = result.reservations;
                this.lastReservations = result.last_reservations;
                for(let i = 0; i < this.services.length; i++)
                {    
                    let start = addHours(startOfDay(this.services[i].admission_date), 1);
                    let end = start;
                    let title  = this.services[i].total;
                    let draggable = false;
                    let array = { 'start': start, 'end': end, 'title': title, 'draggable': draggable };
                    this.events.push(array);
                    
                }
                let b = 'today';
                document.getElementById(b).click();
            },
            err => 
            {
                //console.log(err);
            }
        ); 
    }

    editReservation(reservationId)
    {    
        sessionStorage.setItem('reservation_id', reservationId);
        this.router.navigate(['/reservations/form']);
    }
}
