import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
defineLocale('es', esLocale);
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SessionService } from '../session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit {

    private user = {name:'', url_image:''};
    public permissions = [];
    locale = 'es';

    public reservations = [];
    public pages = [];
    public currentPage = 0;
    public search = '';
    public rows = 10;

	public view = '';
	public step = 1;
	public visitants = [];
	public bedrooms = [];
    public total_value_bedrooms = 0;
	public services = [];
    public total_value_services = 0;
    public list_services = [];
    public guides = [];
    public total_value_reservation = 0;
    public countries = [];
    public selectedCountry = null;

	public showCanvasRight = false;
    public reservationId = '';
    public reservation = {id:0, customer_id:0, admission_date:'', departure_date:'', total_value:0, confirmed: 0, payment:0};
	public reservation_form: FormGroup;
  	checkboxValue: any;
  	facturation_form: FormGroup;
    email_share: FormGroup;
    public services_dates = [];

    @ViewChild('modal') modal;
    modalRef: BsModalRef;
        config = {
        animated: true
    };
    public email = '';
    public message = '';

    public customerType = 1;
    public customers = [];
    public searchCustomers = '';
    public selectedCustomer = null;
    public documentType = '';
    public validateDocumentType = false;
    public documentNumber = '';
    public validateDocumentNumber = false;

    public aux_bedrooms = [];

    public canvas = false;

    public btnText:string = 'Guardar y finalizar';
    public documentFindNumber:string = '';

    public archivos = "";
    public document_archiveImg="";
    public documentoUrl= "";

  	constructor(public service: ApirestService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                fb: FormBuilder,
                private localeService: BsLocaleService,
                private router: Router,
                private modalService: BsModalService,
                public session: SessionService,
                public route: ActivatedRoute)
  	{

        this.route.params.subscribe(params => {
            this.view = params['view'];
            this.showCanvasRight = false;
        });
        this.localeService.use(this.locale);
  		this.reservation_form = fb.group({
            'responsible': ['',  Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
            'phone': [''],
            'admission_date': ['', Validators.required],
            'departure_date': ['', Validators.required],
            'observations': [''],
            'date': ['']
	    });
	    this.facturation_form = fb.group({
            'id': [0],
	    	'responsible': ['',  Validators.compose([Validators.required, Validators.minLength(4)])],
	    	'email': ['', Validators.compose([Validators.required, Validators.email])],
	    	'address': ['', Validators.required],
	    	'phone': ['', Validators.compose([Validators.required, Validators.minLength(7)])],
            'document_archive': [''],
            'invoice': [''],
            'invoice_date': [''],
            'customerType': [1]
	    });

        this.email_share = fb.group({
            'email': ['', Validators.compose([Validators.required, Validators.email])],
            'status': [false, Validators.required],
            'payment': [false, Validators.required],
        });
  	}

  	ngOnInit() {
        this.getUser();
        this.getReservations('');
        this.getServices();
        this.getGuides();
        this.getCountries();
        this.getCustomers();
        this.getBedrooms();
        this.reservationId = sessionStorage.getItem('reservation_id');
        let id = parseInt(this.reservationId);
        if('form' == this.view && id > 0){
            this.reservation['id'] = id;
            this.editReservation(id);
        }

  	}
    
    showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
       setTimeout(()=>{s.spinner.hide()},500);
    }

    actionCanvas()
    {
        if(true == this.canvas)
        {
            this.canvas = false;
        }
        else
        {
            this.canvas = true;
        }

    }

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });
    }

    getReservations(page)
    {
        let url = 'reservations?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;
        }

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.reservations = result.data;
                // console.log(this.reservations[0].id);
                // this.viewDocumentArchiveReservation(this.reservations[0].id);

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    getPage(page)
    {
        if(page != this.currentPage)
        {
            this.getReservations(page);
        }
    }

    getServices()
    {
        let url = 'services';

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.list_services = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    getGuides()
    {
        let url = 'guides-active';

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.guides = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    getCountries()
    {
        let url = 'countries';

        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {
                let result = response.json();
                this.countries = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }


    getBedrooms(){
        let url = 'bedrooms?status=1';

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.aux_bedrooms = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

  	newReservations()
  	{
        this.router.navigate(['/reservations/form']);
  		this.view = 'form';
  		this.step = 1;
  		this.showCanvasRight = true;
        this.visitants = [];
        this.bedrooms = [];
        this.services = [];
        this.reservation_form.reset();
        this.facturation_form.reset();
        this.documentType = '';
        this.documentNumber = '';
        this.total_value_bedrooms = 0;
        this.total_value_services = 0;
        this.total_value_reservation = 0;
        this.reservation = {id:0, customer_id:0, admission_date:'', departure_date:'', total_value:0, confirmed:0, payment:0};
        this.reservationId = '';
        sessionStorage.setItem('reservation_id', '0');
  	}

  	changeStep()
  	{
  		this.step -= 1;
  		if(this.step == 0){
  			this.view = 'index';
  			this.showCanvasRight = false;
            this.visitants = [];
            this.bedrooms = [];
            this.services = [];
            this.total_value_bedrooms = 0;
            this.total_value_services = 0;
            this.total_value_reservation = 0;
            this.reservation = {id:0, customer_id:0, admission_date:'', departure_date:'', total_value:0, confirmed:0, payment:0};
            this.router.navigate(['/reservations/index']);
            this.reservation_form.reset();
            this.facturation_form.reset();
            this.reservationId = '';
            sessionStorage.setItem('reservation_id', '0');
            this.getReservations(this.currentPage);
  		}
  	}

    changeSteps(step)
    {
        if(this.reservation['id'] > 0){
            this.step = step;
        }
    }

    addStep()
    {
        this.step += 1;
    }

  	changeVisitants(type)
  	{
  		if(type == 1)
  		{
  			var data = {id:0, name:'', document:'', birthdate:'', origin:'', nationality:'', selectedNationality:''};
  			this.visitants.push(data);
  		}
  		else
  		{
  			this.visitants.pop();
  		}
  	}

  	deleteVisitant(i, id)
  	{
        if(id > 0)
        {
            this.service.queryGet('reservation-visitor?id='+id).subscribe(
                response=>
                {
                    let result = response.json();
                    if(!result.success)
                    {
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                 },
                err =>
                {
                    let message = 'Parece que tenemos un error, intentalo mas tarde.';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );
        }
  		this.visitants.splice(i, 1);
  	}

    showIndex()
    {
        this.router.navigate(['/reservations/index']);
        this.view = 'index';
        this.step = 0;
        this.visitants = [];
        this.bedrooms = [];
        this.services = [];
        this.reservation_form.reset();
        this.facturation_form.reset();

        this.total_value_bedrooms = 0;
        this.total_value_services = 0;
        this.total_value_reservation = 0;
        this.showCanvasRight = false;
        this.getReservations(this.currentPage);
        this.total_value_bedrooms = 0;
        this.reservation = {id:0, customer_id:0, admission_date:'', departure_date:'', total_value:0, confirmed:0, payment:0};
        this.reservationId = '';
        sessionStorage.setItem('reservation_id', '0');
    }

  	save()
  	{
        //console.log(this.reservation['id']);
        for (let v in this.reservation_form.controls) {
          this.reservation_form.controls[v].markAsTouched();
        }

        if (this.reservation_form.invalid) {
            return;
        }

        this.showLoader();
        let aux_visitors = JSON.stringify(this.visitants);
        let admission_date =  moment(this.reservation_form.controls['admission_date'].value).format("YYYY-MM-DD");
        let departure_date =  moment(this.reservation_form.controls['departure_date'].value).format("YYYY-MM-DD");
        let observations = this.reservation_form.controls['observations'].value;
        let phone = this.reservation_form.controls['phone'].value;
        let body = new FormData();
        body.append('responsible', this.reservation_form.controls['responsible'].value);
        if(null != phone){
            body.append('phone', this.reservation_form.controls['phone'].value);
        }
        if(null != observations){
            body.append('observations', this.reservation_form.controls['observations'].value);
        }
        body.append('admission_date', admission_date);
        body.append('departure_date', departure_date);
        body.append('visitors', aux_visitors);

        this.service.queryPost('reservations', body).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                if(result.success){
                    let aux_reservation = result.reservation;
                    this.reservation.id = aux_reservation.id;
                    this.reservation.customer_id = aux_reservation.customer_id;
                    this.reservation.admission_date = aux_reservation.admission_date;
                    this.reservation.departure_date = aux_reservation.departure_date;
                    this.toastr.success(result.message);
                    this.getReservations(this.currentPage);
                    this.facturation_form.reset();
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
  	}

  	addRoom()
  	{
  		var data = {id: 0, type:'', bedroom_id:'', quantity:0, availability:'', aux_bedrooms: this.aux_bedrooms, value:0};
  		this.bedrooms.push(data);
  	}

  	deleteRoom(i, id)
  	{
        if(id > 0)
        {
            this.service.queryGet('reservation-room?id='+id).subscribe(
                response=>
                {
                    let result = response.json();
                    if(!result.success)
                    {
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                 },
                err =>
                {
                    let message = 'Parece que tenemos un error, intentalo mas tarde.';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );
        }
        let value = this.bedrooms[i]['value'];
        this.total_value_bedrooms = this.total_value_bedrooms - value;
        this.total_value_reservation = this.total_value_bedrooms + this.total_value_services;
  		this.bedrooms.splice(i, 1);
  	}

  	addService()
  	{
        let bedrooms = this.bedrooms.length;
        let date = '';
        if(0 == bedrooms)
        {
            date = moment(this.reservation['admission_date']).format("YYYY-MM-DD");
        }
  		var data = {id:0, service_id:'', guidance:false, guidance_type:'', guide_id:'', guide_name:'', availability:'', value:0, date:date, quantity: 0, selectedGuide:''};
  		this.services.push(data);
  	}

  	deleteService(i, id)
  	{
        if(id > 0)
        {
            this.service.queryGet('reservation-service?id='+id).subscribe(
                response=>
                {
                    let result = response.json();
                    if(!result.success)
                    {
                        this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                 },
                err =>
                {
                    let message = 'Parece que tenemos un error, intentalo mas tarde.';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );
        }
        let value = this.services[i]['value'];
        this.total_value_services = this.total_value_services - value;
        this.total_value_reservation = this.total_value_bedrooms + this.total_value_services;
  		this.services.splice(i, 1);
  	}

    captureFile(event): any {
        const archiveCapture = event.target.files[0];
        this.archivos = archiveCapture;
    }


  	saveBilling()
  	{
  		for (let v in this.facturation_form.controls) {
	      this.facturation_form.controls[v].markAsTouched();
	    }
  
        if('' === this.documentType) {
            this.validateDocumentType = true;
            return;
        }else {
            this.validateDocumentType = false;
        }

        if('' === this.documentNumber) {
            this.validateDocumentNumber = true;
            return;
        }else {
            this.validateDocumentNumber = false;
        }

	    if (this.facturation_form.invalid) {
	    	return;
	    }

        let total_value_reservation = this.total_value_reservation.toString().replace(/\,/g,'');

        let invoice_date = this.facturation_form.controls['invoice_date'].value;
        if('' !== invoice_date && null != invoice_date){
            invoice_date = moment(invoice_date).format("YYYY-MM-DD");
        }else{
            invoice_date = '';
        }

        let body = new FormData();
        body.append('id', this.facturation_form.controls['id'].value);
        body.append('reservation_id', this.reservation['id']+'');
        body.append('customer_id', this.reservation['customer_id']+'');
        body.append('document_type', this.documentType);
        body.append('document_number', this.documentNumber);
        body.append('responsible', this.facturation_form.controls['responsible'].value);
        body.append('email', this.facturation_form.controls['email'].value);
        body.append('address', this.facturation_form.controls['address'].value);
        body.append('phone', this.facturation_form.controls['phone'].value);
        body.append('invoice', this.facturation_form.controls['invoice'].value);
        body.append('invoice_date', invoice_date);
        body.append('total_value', total_value_reservation);
        body.append('document_archive', this.archivos);

        this.service.queryPost('reservations-billing', body).subscribe(
            response=>
            {
                let result = response.json();

                if (result.message=='Debe adjuntar el documento') {
                    console.log(result.message);
                   this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }

                if(result.message === 'ya registrado'){
                    let message = 'El documento ya esta registrado';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }

                if(result.success){
                    this.step = 0;
                    this.visitants = [];
                    this.bedrooms = [];
                    this.services = [];
                    this.showCanvasRight = false;
                    this.getReservations(this.currentPage);
                    this.view = 'index';
                    this.toastr.success(result.message);
                    this.reservation = {id:0, customer_id:0, admission_date:'', departure_date:'', total_value:0, confirmed:0, payment:0};
                    this.facturation_form.reset();
                    this.reservation_form.reset();
                    this.documentType = '';
                    this.documentNumber = '';
                    this.documentoUrl= '';
                    this.reservationId = '';
                    sessionStorage.setItem('reservation_id', '0');
                    this.total_value_bedrooms = 0;
                    this.total_value_services = 0;
                    this.total_value_reservation = 0;
                }
                else{
                    let message = result.message;
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );

  	}

    changeOrigin(i)
    {
        var origin = this.visitants[i]['origin'];
        if(origin == 'nacional')
        {
            this.visitants[i]['nationality'] = 'Colombia';
        }
        else
        {
            this.visitants[i]['nationality'] = '';
        }
    }

    changeBedroom(i)
    {
        var type = this.bedrooms[i]['type'];
        if(type == 'completa')
        {
            this.bedrooms[i]['quantity'] = 1;
        }
        else
        {
            this.bedrooms[i]['quantity'] = 0;
        }
        this.selectBedroom(i);
    }

    changeBedroomAux(i, type)
    {
        let url = 'bedrooms-type?type=' + type;

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.bedrooms[i]['aux_bedrooms'] = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    saveBedrooms()
    {
        let aux_bedrooms = JSON.stringify(this.bedrooms);

        let body = new FormData();
        body.append('reservation_id', this.reservation['id']+'');
        body.append('bedrooms', aux_bedrooms);

        this.service.queryPost('reservation-bedrooms', body).subscribe(
            response=>
            {
                let result = response.json();
                this.hideLoader();
                if(result.success){
                    if(result.is_new && result.services.length > 0)
                    {
                        let services = result.services;
                        if(services.length > 0)
                        {
                            for (let i = 0; i < services.length; i++) {
                                let id = services[i].id;
                                let service_id = services[i].service_id;
                                let guidance_type = '';
                                let guidance = false;

                                if(services[i].guide_name != '' || services[i].guide_name != null){
                                    guidance_type = 'externo';
                                    guidance = true;
                                }
                                else if(services[i].guide_id != '' || services[i].guide_name != null && services[i].guide_id > 0){
                                    guidance_type = 'interno';
                                    guidance = true;
                                }

                                let guide_id = services[i].guide_id;
                                let guide_name = services[i].guide_name;
                                let availability = services[i].availability;
                                let value = services[i].value;
                                let date = services[i].date;
                                let quantity = services[i].quantity;
                                let data = {id:id, service_id:service_id, guidance:guidance, guidance_type:guidance_type, guide_id:guide_id, guide_name:guide_name, availability:'', value: value, date: date, quantity: quantity};
                                this.services.push(data);
                            }
                        }
                    }
                    this.services_dates = this.daysBetween(this.reservation['admission_date'], this.reservation['departure_date']);
                    this.toastr.success(result.message);
                    let bedrooms = result.allBedrooms;
                    if(bedrooms.length > 0)
                    {
                        this.bedrooms = [];
                        this.total_value_bedrooms = 0;
                        for (let i = 0; i < bedrooms.length; i++) {
                            let id = bedrooms[i].id;
                            let type = bedrooms[i].type;
                            let bedroom_id = bedrooms[i].bedroom_id;
                            let quantity = bedrooms[i].quantity;
                            let availability = bedrooms[i].available;
                            let value = bedrooms[i].value;
                            let data = {id: id, type: type, bedroom_id: bedroom_id, quantity:quantity, availability:availability, aux_bedrooms:this.aux_bedrooms, value: value};
                            this.bedrooms.push(data);
                            this.total_value_bedrooms +=  parseFloat(bedrooms[i].value);
                        }
                    }
                }
                else{
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    daysBetween(from, to) {
        const fromDate = moment(new Date(from)).startOf('day');
        const toDate = moment(new Date(to)).endOf('day');

        const span = Math.ceil(moment.duration(toDate.diff(fromDate)).asDays());
        const days = [];
        for (let i = 1; i <= span; i++) {
            days.push(moment(fromDate).add(i, 'day').startOf('day').format("YYYY-MM-DD"));
        }
        return days;
    }


    changeService(i)
    {
        let service = this.services[i]['service_id'];
        let url = 'services/'+service;

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                if(1 == result.guide_type){
                    this.services[i]['guidance'] = true;
                    this.services[i]['guide_name'] = '';
                    this.services[i]['guide_id'] = '';
                }
                else
                {
                    this.services[i]['guidance'] = false;
                    this.services[i]['guide_name'] = '';
                    this.services[i]['guide_id'] = '';
                }
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    saveServices()
    {
        let aux_services = JSON.stringify(this.services);

        let body = new FormData();
        body.append('reservation_id', this.reservation['id']+'');
        body.append('services', aux_services);

        this.service.queryPost('reservation-services', body).subscribe(
            response=>
            {
                let result = response.json();
                if(result.success)
                {
                    let services = result.allServices;
                    if(services.length > 0)
                    {
                        this.services = [];
                        this.total_value_services = 0;
                        for (let i = 0; i < services.length; i++) {
                            let id = services[i].id;
                            let service_id = services[i].service_id;
                            let guidance_type = '';
                            let guidance = false;

                            if(services[i].guide_name != '' && services[i].guide_name != null){
                                guidance_type = 'externo';
                                guidance = true;
                            }
                            else if(services[i].guide_id != '' && services[i].guide_id > 0){
                                guidance_type = 'interno';
                                guidance = true;
                            }

                            let guide_id = services[i].guide_id;
                            let guide_name = services[i].guide_name;
                            let availability = services[i].available;
                            let value = services[i].value;
                            let date = services[i].date;
                            let quantity = services[i].quantity;
                            let selectedGuide = services[i].guide;
                            let data = {id:id, service_id:service_id, guidance:guidance, guidance_type:guidance_type, guide_id:guide_id, guide_name:guide_name, availability: availability, value: value, date: date, quantity: quantity, selectedGuide: selectedGuide};
                            this.services.push(data);
                            this.total_value_services +=  parseFloat(services[i].value);
                        }
                    }
                    this.toastr.success(result.message);
                }
                else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
               //console.log(err);
            }
        );
    }

    editReservation(reservationId)
    {
        this.showLoader();
        this.reservation_form.reset();
        this.facturation_form.reset();
        this.visitants = [];
        this.services = [];
        this.bedrooms = [];
        this.total_value_bedrooms = 0;
        this.total_value_services = 0;
        this.total_value_reservation = 0;
        this.documentType = '';
        this.documentNumber = '';
        sessionStorage.setItem('reservation_id', reservationId);
        this.service.queryGet('reservations/'+reservationId).subscribe(
            response=>
            {
                let result = response.json();
                let reservation = result.reservation;
                this.reservation['id'] = reservation.id;
                this.reservation_form.controls['responsible'].setValue(reservation.responsible);
                if(null != reservation.phone){
                    this.reservation_form.controls['phone'].setValue(reservation.phone);
                }
                if(null != reservation.observations){
                    this.reservation_form.controls['observations'].setValue(reservation.observations);
                }
                this.reservation_form.controls['admission_date'].setValue(reservation.admission_date);
                this.reservation_form.controls['departure_date'].setValue(reservation.departure_date);
                this.reservation['admission_date'] = reservation.admission_date;
                this.reservation['departure_date'] = reservation.departure_date;
                this.reservation['confirmed'] = reservation.confirmed;
                this.reservation['payment'] = reservation.payment;
                if(null != reservation.customer_id){
                    this.reservation['customer_id'] = reservation.customer_id;
                }
                this.services_dates = this.daysBetween(reservation.admission_date, reservation.departure_date);
                let visitors = result.visitors;
                if(visitors.length > 0)
                {
                    for (let i = 0; i < visitors.length; i++)
                    {
                        let id = visitors[i].id;
                        let name = visitors[i].name;
                        let document = visitors[i].document;
                        let birthdate = visitors[i].birthdate;
                        let origin = visitors[i].origin;
                        let nationality = visitors[i].nationality;
                        let selectedNationality;
                        if(origin == 'nacional'){
                            selectedNationality = visitors[i].nationality;
                        }
                        else{
                            selectedNationality = {id: visitors[i].country, name: visitors[i].nationality};
                        }

                        let data = {id: id, name:name, document:document, birthdate:birthdate, origin:origin, nationality:nationality, selectedNationality: selectedNationality};
                        this.visitants.push(data);
                    }
                }

                let bedrooms = result.bedrooms;
                if(bedrooms.length > 0)
                {
                    for (let i = 0; i < bedrooms.length; i++) {
                        let id = bedrooms[i].id;
                        let type = bedrooms[i].type;
                        let bedroom_id = bedrooms[i].bedroom_id;
                        let quantity = bedrooms[i].quantity;
                        let availability = bedrooms[i].available;
                        let value = bedrooms[i].value;
                        let data = {id: id, type: type, bedroom_id: bedroom_id, quantity:quantity, availability:availability, aux_bedrooms:this.aux_bedrooms, value: value};
                        this.bedrooms.push(data);
                        this.total_value_bedrooms +=  parseFloat(bedrooms[i].value);
                    }
                }

                let services = result.services;
                if(services.length > 0)
                {
                    for (let i = 0; i < services.length; i++) {
                        let id = services[i].id;
                        let service_id = services[i].service_id;
                        let guidance_type = '';
                        let guidance = false;

                        if(services[i].guide_name != '' && services[i].guide_name != null){
                            guidance_type = 'externo';
                            guidance = true;
                        }
                        else if(services[i].guide_id != '' && services[i].guide_id > 0){
                            guidance_type = 'interno';
                            guidance = true;
                        }

                        let guide_id = services[i].guide_id;
                        let guide_name = services[i].guide_name;
                        let availability = services[i].available;
                        let value = services[i].value;
                        let date = services[i].date;
                        let quantity = services[i].quantity;
                        let selectedGuide = services[i].guide;
                        let data = {id:id, service_id:service_id, guidance:guidance, guidance_type:guidance_type, guide_id:guide_id, guide_name:guide_name, availability: availability, value: value, date: date, quantity: quantity, selectedGuide: selectedGuide};
                        this.services.push(data);
                        this.total_value_services +=  parseFloat(services[i].value);
                    }
                }

                let customer = result.customer;
                let invoice = result.invoice;
                if(null != invoice)
                {
                    this.facturation_form.controls['id'].setValue(invoice.id);
                    this.documentType = customer.document_type;
                    this.documentNumber = customer.document_number;
                    this.facturation_form.controls['responsible'].setValue(customer.responsible);
                    this.facturation_form.controls['email'].setValue(customer.email);
                    this.facturation_form.controls['address'].setValue(customer.address);
                    this.facturation_form.controls['phone'].setValue(customer.phone);
                    this.facturation_form.controls['invoice'].setValue(invoice.invoice);
                    this.facturation_form.controls['invoice_date'].setValue(invoice.invoice_date);
                    //this.facturation_form.controls['document_archive'].setValue(customer.document_archive);
                    this.documentoUrl=customer.document_archive;
                    //console.log(this.documentoUrl);
                }

                this.router.navigate(['/reservations/form']);
                this.view = 'form';
                this.step = 1;
                this.showCanvasRight = true;
                this.total_value_reservation = this.total_value_bedrooms + this.total_value_services;
                this.hideLoader();
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    update()
    {
        this.showLoader();
        let observations = this.reservation_form.controls['observations'].value;
        let phone =  this.reservation_form.controls['phone'].value;
        let id = this.reservation['id'];
        let aux_visitors = JSON.stringify(this.visitants);
        let admission_date =  moment(this.reservation_form.controls['admission_date'].value).format("YYYY-MM-DD");
        let departure_date =  moment(this.reservation_form.controls['departure_date'].value).format("YYYY-MM-DD");

        let body = new FormData();
        body.append('_method', 'PUT');
        body.append('responsible', this.reservation_form.controls['responsible'].value);

        if(null != phone){
            body.append('phone', this.reservation_form.controls['phone'].value);
        }
        if(null != observations){
            body.append('observations', this.reservation_form.controls['observations'].value);
        }
        body.append('admission_date', admission_date);
        body.append('departure_date', departure_date);
        body.append('visitors', aux_visitors);

        this.service.queryPost('reservations/'+id+'', body).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                if(result.success){
                    let aux_reservation = result.reservation;
                    this.reservation.id = aux_reservation.id;
                    this.reservation.customer_id = aux_reservation.customer_id;
                    this.reservation.admission_date = aux_reservation.admission_date;
                    this.reservation.departure_date = aux_reservation.departure_date;
                    this.services_dates = this.daysBetween(this.reservation['admission_date'], this.reservation['departure_date']);
                    this.toastr.success(result.message);
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    deleteReservation(reservationId)
    {
        this.service.queryDelete('reservations/'+reservationId).subscribe(
            response=>
            {
                let result = response.json();
                if(result.success)
                {
                    //Reload the list reservations
                    this.getReservations(this.currentPage);
                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                }
            },
            err =>
            {
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /**
     * Ask for confirmation before to delete the reservation
     * @param string reservationId id reservation to delete
     */
    confirmDelete(reservationId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_cancel'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value)
            {
                this.deleteReservation(reservationId);
            }
        });
    }

    /**
     * Ask for confirmation before to update the reservation
     * @param values reservationId, reservationConfirmed,  reservation to confirm
     */
    confirmReservation(reservationId, reservationConfirmed)
    {
        if(reservationConfirmed == 0){
            this.message = 'Estás seguro de confirmar la reserva?';
        }
        else{
            this.message = 'Estás seguro de eliminar la confirmación de la reserva?';
        }
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value)
            {
                this.changeConfirmed(reservationId, reservationConfirmed);
            }
        });
    }

    /**
     * Ask for confirmation before to update the reservation
     * @param values reservationId, reservationConfirmed,  reservation to confirm payment
     */
    confirmPayment(reservationId, reservationConfirmed)
    {
        if(reservationConfirmed == 0){
            this.message = 'Estás seguro de confirmar el pago de la reserva?';
        }
        else{
            this.message = 'Estás seguro de eliminar la confirmación de pago de la reserva?';
        }
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value)
            {
                this.changePayment(reservationId, reservationConfirmed);
            }
        });
    }

    changeConfirmed(reservationId, reservationConfirmed)
    {
        let confirmed = reservationConfirmed;
        if(confirmed == 0){
            confirmed = '1';
        } else
        {
            confirmed = '0';
        }

        let url = 'reservations/change-confirmed/'+ reservationId;
        let body = new FormData();
        body.append('confirmed', confirmed);

        this.service.queryPost(url, body).subscribe(
            response=>
            {
                let result = response.json();
                if(result.success)
                {
                    this.getReservations(this.currentPage);
                    this.toastr.success(result.message);
                }
                else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }

            },
            err =>
            {
                let message = 'Parece que tenemos un error, inténtalo de nuevo más tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
        this.message = '';
    }

    changePayment(reservationId, reservationPayment)
    {
        let payment = reservationPayment;
        if(payment == 0)
        {
            payment = '1';
        }
        else
        {
            payment = '0';
        }

        let url = 'reservations/change-payment/'+ reservationId;
        let body = new FormData();
        body.append('payment', payment);

        this.service.queryPost(url, body).subscribe(
            response=>
            {
                let result = response.json();
                if(result.success)
                {
                    this.getReservations(this.currentPage);
                    this.toastr.success(result.message);
                }
                else
                {
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }

            },
            err =>
            {
                let message = 'Parece que tenemos un error, inténtalo de nuevo más tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
        this.message = '';
    }

    /*
    * Validate availability of rooms
    */
    totalAccommodation(i, action)
    {
        let quantity: number = this.bedrooms[i]['quantity'];
        let id = this.bedrooms[i]['bedroom_id'];

        let start = this.reservation['admission_date'];
        let end = this.reservation['departure_date'];
        let nights =  this.daysBetween(start, end);
        let totalDays = nights.length - 1;

        if(quantity.toString() != "" && 0 != quantity && action == 1 && '' != id){
            let total: number = 0;
            let type = this.bedrooms[i]['type'];
            let url = '';

            url = 'bedrooms/' + id + '/availability?start_date='+ this.reservation['admission_date'] + '&end_date='
            + this.reservation['departure_date'] + "&quantity=" + quantity + "&type=" + type;

            this.service.queryGet(url).subscribe(
                response=>
                {
                    let result = response.json();
                    if(type == 'completa')
                    {
                        total = parseFloat(result.full_rate);
                    }
                    else
                    {
                        total = parseFloat(result.single_rate) * quantity;
                    }

                    if(totalDays > 0){
                        total = total * totalDays;
                    }

                    let text = '';
                    if(result.available){
                        text = 'Si';
                    }
                    else{
                        text = 'No';
                    }
                    this.bedrooms[i]['availability'] = text;
                    this.bedrooms[i]['value'] = total;
                },
                err =>
                {
                    let message = 'Parece que tenemos un error, intentalo mas tarde.';
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            );
        }
        else
        {
            this.bedrooms[i]['availability'] = '';
            this.bedrooms[i]['value'] = 0;
        }

        let Value = document.getElementsByClassName("value-room");
        let self = this;
        setTimeout(() => {
            self.total_value_bedrooms = 0;
            for(let i  = 0; i < Value.length; i++)
            {
                self.total_value_bedrooms += parseInt(Value[i]['value']);
            }
            self.total_value_reservation = self.total_value_services + self.total_value_bedrooms;
        }, 500);
    }

    lessAccommodation(i)
    {
        let total = this.bedrooms[i]['value'];
        this.total_value_bedrooms -= total;
    }

    selectBedroom(i)
    {
        this.lessAccommodation(i);
        let type = this.bedrooms[i]['type'];
        let action = 0;
        if('completa' == type){
            action = 1;
        } else if('camas' == type){
            let quantity: number = this.bedrooms[i]['quantity'];
            if(quantity.toString() != "" && 0 != quantity){
                action = 1;
            }
        }
        this.totalAccommodation(i, action);
    }

    onItemSelect(item:any, i)
    {
        let country = item;
        if(null != country)
        {
            this.visitants[i]['nationality'] = country.name;
        }
        else
        {
            this.visitants[i]['nationality'] = '';
        }
    }

    onGuideSelect(item:any, i)
    {
        let guide = item;
        if(null != guide)
        {
            this.services[i]['guide_id'] = guide.id;
        }
        else
        {
            this.services[i]['guide_id'] = '';
        }
    }

    /* Validate the availability services
    **/
    validateAvailability(i)
    {
        let quantity = this.services[i].quantity;
        let id = this.services[i].service_id;
        let total = 0;
        let type = this.services[i].type;
        let aux_date = this.services[i].date;

        if('' == id){
            this.toastr.error("Selecciona un servicio", 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
        else if('' == aux_date || undefined == aux_date){
            this.toastr.error("Selecciona una fecha", 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
        }
        else{
            let date = moment(this.services[i].date).format("YYYY-MM-DD");
            let value = this.services[i].value;

            if(quantity.toString() != "" && 0 != quantity && id != '' && date != '')
            {
                let url = 'services/' + id + '/availability?date='+ date + "&quantity=" + quantity;

                this.service.queryGet(url).subscribe(
                    response=>
                    {
                        let result = response.json();
                        total = result.total;
                        let text = '';
                        if(result.available){
                            text = 'Si';
                        }
                        else{
                            text = 'No';
                        }
                        this.services[i]['availability'] = text;
                        this.services[i]['value'] = total;
                    },
                    err =>
                    {
                        let message = 'Parece que tenemos un error, intentalo mas tarde.';
                        this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                    }
                );
            }

            let Value = document.getElementsByClassName("value-service");
            let self = this;
            setTimeout(() => {
                self.total_value_services = 0;
                for(let i  = 0; i < Value.length; i++)
                {
                    self.total_value_services += parseInt(Value[i]['value']);
                }
                self.total_value_reservation = self.total_value_services + self.total_value_bedrooms;
            }, 500);
        }
    }

    /* Open modal to update customer email
    */
    share()
    {
        let email = this.facturation_form.controls['email'].value;
        this.email_share.controls['email'].setValue(email);
        this.modalRef = this.modalService.show(this.modal, {class: 'modal-md', ignoreBackdropClick: true});
    }

    /* Send mail to update visitants information
    */
    shareReservation()
    {
        for (let v in this.email_share.controls) {
          this.email_share.controls[v].markAsTouched();
        }

        if (this.email_share.invalid) {
            return;
        }

        this.showLoader();

        let status = '0';
        if(this.email_share.controls['status'].value){
            status = '1';
        }

        let body = new FormData();
        body.append('reservation_id', this.reservation['id']+'');
        body.append('email', this.email_share.controls['email'].value);
        body.append('status', status);

        //Habilite payment only when is confirmed the reservation
        if(1 == this.reservation['confirmed'])
        {
            let payment = '0';
            if(this.email_share.controls['payment'].value){
                payment = '1';
            }
            body.append('payment', payment);
        }

        this.service.queryPost('reservations/share', body).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                if(result.success)
                {
                    this.modalRef.hide();
                    this.toastr.success(result.message);
                }
                else
                {
                    let message = result.message;
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /*
    * Generate abstract pdf of reservation
    */
    export()
    {
        this.showLoader();
        let id = this.reservation['id'];
        let url = 'reservations/' + id + '/export';

        this.service.queryGet(url).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                window.open(result, '_blank');
            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    /*
    * Generate invoice excel
    */
    invoiceReservation(reservationId)
    {
        this.showLoader();

        this.service.queryGet('reservations/invoice/'+reservationId).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                if(result.success){
                   window.open(result.report, '_blank');
                }
                else{
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
                }

            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    viewDocumentArchiveReservation(reservationId){
        this.showLoader();

        this.service.queryGet('reservations/'+reservationId).subscribe(
            response=>
            {
                this.hideLoader();
                let result = response.json();
                let document_customer=result.customer.document_archive;
                if(document_customer==null || document_customer==""){
                    this.toastr.error(result.message, 'Error, el cliente no contiene un documento adjuntado', {enableHtml: true, positionClass: 'toast-top-center'});
                }
                else{
                    if (document_customer.substr(-4)==".pdf") {
                        window.open(environment.apiDoc+document_customer);
                    } else if (document_customer.substr(-4)==".img" ||  document_customer.substr(-4)==".png" ||  document_customer.substr(-4)==".jpg") {
                        this.document_archiveImg=environment.apiDoc+document_customer;
                        console.log(this.document_archiveImg);
                        this.openModal();
                    }
                    
                }

            },
            err =>
            {
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );

    }

    getCustomers()
    {
        let url = 'customers-active';

        this.service.queryGet(url).subscribe(
            response=>
            {
                let result = response.json();
                this.customers = result;
            },
            err =>
            {
                //console.log(err);
            }
        );
    }

    changeCustomerType(type)
    {
      if (type == 2) {
        this.btnText = 'Actualizar'
        this.getCustomers();
      } else {
        this.btnText = 'Guardar y finalizar'
      }
      this.facturation_form.reset();
      this.documentType = '';
      this.validateDocumentType = false;
      this.documentNumber = '';
      this.reservation['customer_id'] = null;
      this.documentFindNumber = '';
      this.validateDocumentNumber = false;
      // let customerType = type;
      // if(2 == type){
      //     this.getCustomers();
      // } else {
      //     this.facturation_form.reset();
      //     this.documentType = '';
      //     this.validateDocumentType = false;
      //     this.documentNumber = '';
      //     this.validateDocumentNumber = false;
      // }
    }

    onCustomerSelect(item:any)
    {
        if(undefined != item){
            let customer = item;
            this.showLoader();
            this.facturation_form.controls['responsible'].setValue(item.responsible);
            this.facturation_form.controls['email'].setValue(item.email);
            this.facturation_form.controls['address'].setValue(item.address);
            this.facturation_form.controls['phone'].setValue(item.phone);
            this.documentType = item.document_type;
            this.documentNumber = item.document_number;
            this.reservation['customer_id'] = item.id;
            this.hideLoader();
        } else {
            this.facturation_form.reset();
            this.documentType = '';
            this.documentNumber = '';
            this.reservation['customer_id'] = 0;
        }
    }

    /**
     * DataList
     */
    getCustomerByDocumentNumber(event) {
        this.service.queryGet(`customers?rows=5&search=${ event.target.value }`).subscribe(response => {
          const { data } = response.json();
          this.customers = data;
        });
        console.log('Keyup event', this.customers);
      }

      getCustomerId(event) {
        let customer = this.customers.find(value => value.document_number == event.target.value);
        if (customer) {
            this.documentType = customer.document_type
            this.reservation.customer_id = customer.id;
            this.facturation_form.controls['responsible'].setValue(customer.responsible);
            this.facturation_form.controls['email'].setValue(customer.email);
            this.facturation_form.controls['address'].setValue(customer.address);
            this.facturation_form.controls['phone'].setValue(customer.phone);
        } else {
            this.facturation_form.reset();
            this.documentType = '';
            this.documentNumber = '';
        }
        console.log('Change event', customer);
        console.log('Reservation Form', this.reservation);
      }

    /**
    * Function to validate numbers
    *
    * @param e EventHtml
    */
    public validateNumber(e)
    {
        if(e.code !== "Backspace" && e.code !== "Tab" && e.code !== "ShiftRight"
         && e.code !== "Home" && e.code !== "ShiftLeft" && e.code !== "End" && e.code !== "MetaLeft"){
            var key = window.event ? e.which : e.keyCode;
            if (key < 48 || key > 57) {
                e.preventDefault();
            }
        }
    }

    openModal() {
        this.modalRef = this.modalService.show(this.modal, {class: 'modal-lg', ignoreBackdropClick: true});
    }
}
