import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SingletonService } from '../singleton.service';
import { ApirestService } from '../apirest.service';
import { SessionService } from '../session.service';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneModule ,DropzoneComponent , DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    private user = {name:'', url_image:''};
    public permissions = [];

	service_form: FormGroup;
  	checkboxValue: any;

  	public view = 'index';
  	public services = [];
  	public pages = [];
    public currentPage = 0;
    public search = '';
    public rows = 10;
    public service_id = 0;
    public images = [];
    public images_aux = [];
    public image: any;
    public pathImage = '';
    public cover: any;
    public pathCover = '';
    public daily_capacity = 0;
    public showCapacity = false;

    @ViewChild(DropzoneComponent) drpzone: DropzoneComponent;
    @ViewChild(DropzoneDirective) directiveRef?: DropzoneDirective;

    public config: DropzoneConfigInterface = {
        url: '',
        clickable: true,
        maxFiles: 1,
        autoReset: 1000,
        errorReset: null,
        cancelReset: null
    };

    public myConfig =   {
            scayt_sLang: 'es_ES',
            wsc_lang : 'es_ES',
            disableNativeSpellChecker: false,
            scayt_autoStartup: false,
            removeButtons:'Underline,Subscript,Superscript,SpecialChar,Source', 
            toolbarGroups:[ 
                { name: 'editing', groups: [ 'spellchecker'] },
                { name: 'links', groups: [ 'links' ] },
                { name: 'basicstyles', groups: [ 'basicstyles'] },
                { name: 'paragraph', groups: ['list', 'indent','align', 'paragraph'] }
            ]
    };

    url_uploads = '';
    formData: FormData;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    humanizeBytes: Function;
    dragOver: boolean;
    options: UploaderOptions;

  	constructor(public service: ApirestService,
                public session: SessionService,
                private toastr: ToastrService,
                private translate: TranslateService,
                private singleton: SingletonService,
                private spinner: NgxSpinnerService,
                public route: ActivatedRoute,
                private router: Router,
                fb: FormBuilder) 
  	{    
        this.route.params.subscribe(params => {
            this.view = params['view'];
        }); 
	    this.service_form = fb.group({
	    	'id': [''],
	    	'name': ['',  Validators.compose([Validators.required, Validators.minLength(3)])],
	    	'description': [''],
	    	'capacity': [0],
	    	'person_rate': ['', Validators.required],
	    	'general_rate': ['', Validators.required],
	    	'guide_type': [0, Validators.required],
	    	'status': [true, Validators.required],
            'visible_landing': [true, Validators.required],
            'included_accommodation': [false, Validators.required]
	    });
        this.options = { concurrency: 1, maxUploads: 20 };
        this.files = [];
        this.uploadInput = new EventEmitter<UploadInput>();
        this.humanizeBytes = humanizeBytes;
        this.url_uploads = this.service.apiUrl  + 'save-image';
  	}

  	ngOnInit() {
        this.getUser();
  		this.getServices('');
  	}

    getUser(){
        this.singleton.getUserSession();
        this.session.userSession.subscribe(user => {
            if('' != user)
            {
                this.user = JSON.parse(user);
                this.translate.use(this.user['language']);
                this.permissions = this.user['permissions'];
            }
        });
    }  

    getServices(page)
    {
        let url = 'services?rows=' + this.rows;
        url += '&search=' + this.search;

        if(page != '')
        {
            url += '&page=' + page;            
        }

        this.service.queryGet(url).subscribe(
            response=>
            {      
                let result = response.json();
                this.services = result.data;

                this.currentPage = result['current_page'];
                this.pages = this.singleton.pagination(result);
            },
            err => 
            {
                
            }
        );
    }

    getPage(page)
    {    
        if(page != this.currentPage)
        {
            this.getServices(page);
        }    
    }

  	changeView(view){
        this.router.navigate(['/services/'+view]);  
  		this.view = view;
        this.showCapacity = false;
        this.images = [];
        this.daily_capacity = 0;
        this.service_form.reset();
  	}

    showLoader()
    {
        let s = this;
        setTimeout(()=>{s.spinner.show()},500);
    }

    hideLoader()
    {
        let s = this;
       setTimeout(()=>{s.spinner.hide()},500);
    }  	

  	submitForm() {

	    for (let v in this.service_form.controls) {
	      this.service_form.controls[v].markAsTouched();
	    }

        /*console.log(this.service_form.invalid);*/
	    if (this.service_form.invalid) {
	    	return; // You will get form value if your form is valid
	                //var formValues = this.service_form.getRawValue;
	    }

        let person_rate =  ''
        let general_rate = '';

        if(null != this.service_form.controls['person_rate'].value)
        {
            person_rate = this.service_form.controls['person_rate'].value.replace(/\,/g,'');
        }

        if(null != this.service_form.controls['general_rate'].value)
        {
            general_rate = this.service_form.controls['general_rate'].value.replace(/\,/g,'');
        }

	    let status = '0';
	    if(this.service_form.controls['status'].value){
	    	status = '1';
	    }

        let visible = '0';
        if(this.service_form.controls['visible_landing'].value){
            visible = '1';
        }

        let included = '0';
        if(this.service_form.controls['included_accommodation'].value){
            included = '1';
        }

        this.showLoader();
	    let body = new FormData();
        body.append('name', this.service_form.controls['name'].value);
        body.append('description', this.service_form.controls['description'].value);
        body.append('capacity', this.service_form.controls['capacity'].value);
        body.append('person_rate', person_rate);
        body.append('general_rate', general_rate);
        body.append('guide_type', this.service_form.controls['guide_type'].value);
        body.append('status', status);
        body.append('visible_landing', visible);
        body.append('included_accommodation', included);
        body.append('status', status);
        body.append('image', this.image);
        //body.append('cover', this.cover);
        body.append('daily_capacity', this.daily_capacity+'');

        this.service.queryPost('services', body).subscribe(
            response=>
            {      
                this.hideLoader();
                let result = response.json();
                if(result.success){
                    this.getServices(this.currentPage);
                    this.view = 'index';
                    this.router.navigate(['/services/index']);  
                    this.toastr.success(result.message);
                    this.service_form.reset();
                    this.daily_capacity = 0;
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );  
	};

	edit(serviceId)
    {    
        this.showLoader();
        this.service.queryGet('services/'+serviceId).subscribe(
            response=>
            {     
                this.hideLoader(); 
                let result = response.json(); 
                let service = result;
                this.service_id = service.id;
                this.service_form.controls['id'].setValue(service.id);
                this.service_form.controls['name'].setValue(service.name);
                this.service_form.controls['description'].setValue(service.description);
                this.service_form.controls['capacity'].setValue(service.capacity);
                this.service_form.controls['person_rate'].setValue(service.person_rate);
                this.format('', 'person_rate');
                this.service_form.controls['general_rate'].setValue(service.general_rate);
                this.format('', 'general_rate');
                this.service_form.controls['guide_type'].setValue(service.guide_type);
                if(service.guide_type == 1)
                {
                    this.showCapacity = true;
                }
                else{
                    this.showCapacity = false;
                }

                if(service.status == 1){
                	this.service_form.controls['status'].setValue(true);
                }
                else{
                	this.service_form.controls['status'].setValue(false);	
                }

                if(service.visible_landing == 1){
                    this.service_form.controls['visible_landing'].setValue(true);
                }
                else{
                    this.service_form.controls['visible_landing'].setValue(false);    
                }


                if(service.included_accommodation == 1){
                    this.service_form.controls['included_accommodation'].setValue(true);
                }
                else{
                    this.service_form.controls['included_accommodation'].setValue(false);    
                }
                this.daily_capacity = result.daily_capacity;
                this.getImages(this.service_id);
                this.view = 'form';
                this.router.navigate(['/services/form']); 

            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

	update()
    {    
        this.showLoader();
    	let status = '0';
	    if(this.service_form.controls['status'].value){
	    	status = '1';
	    }

        let visible = '0';
        if(this.service_form.controls['visible_landing'].value){
            visible = '1';
        }

        let included = '0';
        if(this.service_form.controls['included_accommodation'].value){
            included = '1';
        }

        let person_rate =  this.service_form.controls['person_rate'].value.replace(/\,/g,'');
        let general_rate = this.service_form.controls['general_rate'].value.replace(/\,/g,'');
	    
        let id = this.service_id;
        
        let body = new FormData();
        body.append('_method', 'PUT');
        body.append('name', this.service_form.controls['name'].value);
        body.append('description', this.service_form.controls['description'].value);
        body.append('capacity', this.service_form.controls['capacity'].value);
        body.append('person_rate', person_rate);
        body.append('general_rate', general_rate);
        body.append('guide_type', this.service_form.controls['guide_type'].value);
        body.append('status', status);
        body.append('visible_landing', visible);
        body.append('included_accommodation', included);
        body.append('image', this.image);
        body.append('cover', this.cover);
        body.append('daily_capacity', this.daily_capacity+'');

        this.service.queryPost('services/'+id+'', body).subscribe(
            response=>
            {   
                this.hideLoader();   
                let result = response.json();
                 if(result.success){
                    this.getServices(this.currentPage);
                    this.view = 'index';
                    this.service_id = 0;
                    this.toastr.success(result.message);
                    this.service_form.reset();
                    this.daily_capacity = 0;
                }
                else{
                    let message = '';
                    if(result.message.length > 0)
                    {
                        result.message.forEach(function(element) {
                            message += element+'<br>';
                        });
                    }
                    this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {    
                this.hideLoader();
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        ); 
    }

    deleteService(serviceId)
    {
        this.service.queryDelete('services/'+serviceId).subscribe(
            response=>
            {      
                let result = response.json(); 

                if(result.success)
                {
                    //Reload the list services
                    this.getServices(this.currentPage);
                    swal(
                        this.translate.instant('alerts.congratulations'),
                        result.message,
                        'success'
                    );
                }else{
                    this.toastr.error(result.message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});    
                }
            },
            err => 
            {
                let message = 'Parece que tenemos un error, intentalo mas tarde.';
                this.toastr.error(message, 'Error', {enableHtml: true, positionClass: 'toast-top-center'});
            }
        );
    }

    confirmDelete(serviceId)
    {
        swal({
            title: this.translate.instant('alerts.confirm'),
            text: this.translate.instant('alerts.sure_to_delete'),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009397',
            cancelButtonColor: '#888888',
            confirmButtonText: this.translate.instant('alerts.yes'),
            cancelButtonText: this.translate.instant('alerts.cancel')
        }).then((result) => {
            if (result.value) 
            {
                this.deleteService(serviceId);
            }
        });
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                var reader = new FileReader();

                reader.onload = (event:any) => {
                   this.images.push(event.target.result); 
                }
                //console.log(event.target.files[i]);
                this.images_aux.push(event.target.files[i]);
                reader.readAsDataURL(event.target.files[i]);
            }
        }
    }

    onUploadSuccess(event)
    {
        console.log(event);
    }

    onUploadError(event)
    {
        console.log(event);
    }

    onSending(data): void 
    {
        // data [ File , xhr, formData]
        console.log(data);
        this.image = data[0];

        //this.saveDocument(body);
    }

    changeImage(event){
        const files: FileList = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.image = files[0];
    }

    changeCover(event){
        const files: FileList = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        this.cover = files[0];
    }

    format(event, type = '')
    {    
        let name = type;
        if('' == name){
            name = event.target.attributes['formcontrolname'].nodeValue;
        }

        let num = this.service_form.controls[name].value.replace(/\,/g,'');
        
        if(!isNaN(parseInt(num))){
          num = num.toString().split('').reverse().join('').replace(/(?=\d*\,?)(\d{3})/g,'$1,');
          num = num.split('').reverse().join('').replace(/^[\,]/,'');
           this.service_form.controls[name].setValue(num);
        }
        else{ alert('Solo se permiten numeros');
          this.service_form.controls[name].setValue(this.service_form.controls[name].value.replace(/[^\d\,]*/g,''));
        }
    }

    changeType()
    {
        let type = this.service_form.controls['guide_type'].value;
        if(1 == type)
        {
            this.showCapacity = true;
        }
        else{
            this.showCapacity = false;
        }
    }

    /** Upload batchs on node */
    onUploadOutputFile(output: UploadOutput): void {
        
        let token = 'Bearer '+ sessionStorage.getItem('sid').substring(6, sessionStorage.getItem('sid').length);
        if (output.type === 'allAddedToQueue') {
            const event: UploadInput = {
                type: 'uploadAll',
                url: this.url_uploads,
                headers: ({"Authorization": token}),
                method: 'POST',
                data: { 
                    ubication_id: this.service_id+'',
                    ubication: '1',
                    image: output.file+''
                }
            };
            this.uploadInput.emit(event);
        } else if (output.type == 'addedToQueue' && typeof output.file != 'undefined') {
          this.files.push(output.file);
        } else if (output.type == 'uploading' && typeof output.file != 'undefined') {
          const index = this.files.findIndex(file => typeof output.file != 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        } else if (output.type == 'cancelled' || output.type == 'removed') {
          this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type == 'dragOver') {
          this.dragOver = true;
        } else if (output.type == 'dragOut') {
          this.dragOver = false;
        } else if (output.type == 'drop') {
          this.dragOver = false;
        } else if (output.type == 'rejected' && typeof output.file !== 'undefined') {
          //console.log(output.file.name + ' rejected');
        } else if (output.type == 'done'){
            let response;
            response = output.file.response;
            if(response.success)
            {    
                this.toastr.success(response.message);
                this.getImages(this.service_id);
            }
            else
            {    
                this.toastr.error(response.message,'Error');
            }
        }
        this.files = this.files.filter(file => file.progress.status != UploadStatus.Done);
    }

    cancelUpload(id: string): void {
        this.uploadInput.emit({ type: 'cancel', id: id });
    }

    removeFile(id: string): void {
        this.uploadInput.emit({ type: 'remove', id: id });
    }

    removeAllFiles(): void {
        this.uploadInput.emit({ type: 'removeAll' });
    }

    getImages(accommodationId)
    {
        let url = 'show-image/' + this.service_id + '?ubication=' + 1;
        
        this.service.queryPostRegular(url, new FormData).subscribe(
            response=>
            {      
                let result = response.json();
                this.images = result;
            },
            err => 
            {
                
            }
        );
    }

    deleteImage(imageId)
    {
       this.service.queryGet('delete-image/'+imageId).subscribe(
            response=>
            {      
                let result = response.json();
                if(result.success)
                {
                    //Reload the list images
                    this.getImages(this.service_id);
                    this.toastr.success(result.message);
                }
            },
            err => 
            {
                
            }
        ); 
    }
}
