import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss']
})
export class TranslateComponent implements OnInit {

    public language= window.navigator.language;
    public language2='';
    
  	constructor(private translate: TranslateService) 
  	{ 
      
    console.log(this.language);
  		translate.setDefaultLang(this.language);
      if(environment.language2 == ''){
        translate.use(this.language);
      }else{
        translate.use(environment.language2);
        this.language = environment.language2;
      }
        
  	}
  	useLanguage(event) 
    {
      this.translate.use(event.target.value);
      environment.language2 = event.target.value;
    }
    
  	ngOnInit() {
  		
  	}

}
