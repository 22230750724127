import { Injectable } from '@angular/core';
import { Http , Headers, RequestOptions } from '@angular/http';
import { SessionService } from './session.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApirestService {
    public apiUrl: string = environment.apiUrl;

	constructor(private http: Http)
    {
        this.http = http;
    }

    /**
    *
    **/
    queryPostRegular(route:string, body)
    {
        let repos = this.http.post(this.apiUrl.concat(route), body);
        return repos;
    }

    queryGet(route:string)
    {
        let token = 'Bearer '+ sessionStorage.getItem('sid').substring(6, sessionStorage.getItem('sid').length);
        let headers = new Headers({ 'Authorization': token});
        let options = new RequestOptions({ headers: headers });
        let repos = this.http.get(this.apiUrl.concat(route), options);
        return repos;
    }

    queryPost(route:string, body)
    {
        let token = 'Bearer '+ sessionStorage.getItem('sid').substring(6, sessionStorage.getItem('sid').length);
        let headers = new Headers({ 'Authorization': token });
        let options = new RequestOptions({ headers: headers });
        let repos = this.http.post(this.apiUrl.concat(route), body, options);
        return repos;
    }

    queryDelete(route:string)
    {
        let token = 'Bearer '+ sessionStorage.getItem('sid').substring(6, sessionStorage.getItem('sid').length);
        let headers = new Headers({ 'Authorization': token});
        let options = new RequestOptions({ headers: headers });
        let repos = this.http.delete(this.apiUrl.concat(route), options);
        return repos;
    }

    queryExternalApi(route)
    {
        let repos = this.http.get(route);
        return repos;
    }
}
