import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { Router } from '@angular/router';
import { ApirestService } from '../apirest.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  	constructor(private session: SessionService,
                public service: ApirestService,
                private router: Router) { }

  	ngOnInit() {
  	}

    redirect(){
  		this.session.validUserSession();
        if(null == sessionStorage.getItem('sid') || 'null' == sessionStorage.getItem('sid') || undefined == sessionStorage.getItem('sid'))
        {
        	this.router.navigate(['/']);
        }
        else{
        	this.router.navigate(['/home']);
        }
    }

}
